import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import logoWhite from "../images/heme-cloud-white-logo.png";
import graphImage from "../images/graph.png";
import graphImageTab from "../images/graph-tab.png";
import graphImageMobile from "../images/graph-mobile.png";
import labIcon from "../images/lab-icon.png";
import patientIcon from "../images/patient-icon.png";
import doctorIcon from "../images/doctor-icon.png";
import screenShot1 from "../images/screenshot-1.png";
import screenShot1Tab from "../images/screenshot-1-tab.png";
import screenShot1Mobile from "../images/screenshot-1-mobile.png";
import screenShot2 from "../images/screenshot-2.png";
import screenShot2Tab from "../images/screenshot-2-tab.png";
import screenShot2Mobile from "../images/screenshot-2-mobile.png";
import screenShot3 from "../images/screenshot-3.png";
import screenShot3Tab from "../images/screenshot-3-tab.png";
import screenShot3Mobile from "../images/screenshot-3-mobile.png";
import screenShot4 from "../images/screenshot-4.png";
import screenShot4Tab from "../images/screenshot-4-tab.png";
import screenShot4Mobile from "../images/screenshot-4-mobile.png";
import screenShot5 from "../images/screenshot-5.png";
import screenShot5Tab from "../images/screenshot-5-tab.png";
import screenShot5Mobile from "../images/screenshot-5-mobile.png";
import screenShot6 from "../images/screenshot-6.png";
import screenShot6Tab from "../images/screenshot-6-tab.png";
import screenShot6Mobile from "../images/screenshot-6-mobile.png";
import logo1 from "../images/lab-logo-1.png";
import logo2 from "../images/lab-logo-2.png";
import logo3 from "../images/lab-logo-3.png";
import logo4 from "../images/lab-logo-4.png";
import logo5 from "../images/lab-logo-5.png";
import logo6 from "../images/lab-logo-6.png";
import logo7 from "../images/lab-logo-7.png";
import logo8 from "../images/lab-logo-8.png";
import logo9 from "../images/lab-logo-9.png";
import logo10 from "../images/lab-logo-10.png";
import playStore from "../images/playstore.png";
import appStore from "../images/appstore.png";
import facebookLogo from "../images/facebook-icon.png";
import linkedLogo from "../images/linked-in-icon.png";
import twitterLogo from "../images/twitter-icon.png";
import "./MainPage.css";

export default function HomeNew() {
    const [graphImageSrc, setGraphImageSrc] = useState(graphImage);
    const [screenShot1Src, setScreenShot1Src] = useState(screenShot1);
    const [screenShot2Src, setScreenShot2Src] = useState(screenShot2);
    const [screenShot3Src, setScreenShot3Src] = useState(screenShot2);
    const [screenShot4Src, setScreenShot4Src] = useState(screenShot2);
    const [screenShot5Src, setScreenShot5Src] = useState(screenShot2);
    const [screenShot6Src, setScreenShot6Src] = useState(screenShot2);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const updateImageSrc = () => {
          if (window.innerWidth <= 480) {
            setGraphImageSrc(graphImageMobile);
            setScreenShot1Src(screenShot1Mobile);
            setScreenShot2Src(screenShot2Mobile);
            setScreenShot3Src(screenShot3Mobile);
            setScreenShot4Src(screenShot4Mobile);
            setScreenShot5Src(screenShot5Mobile);
            setScreenShot6Src(screenShot6Mobile);
          } else if (window.innerWidth <= 825) {
            setGraphImageSrc(graphImageTab);
            setScreenShot1Src(screenShot1Tab);
            setScreenShot2Src(screenShot2Tab);
            setScreenShot3Src(screenShot3Tab);
            setScreenShot4Src(screenShot4Tab);
            setScreenShot5Src(screenShot5Tab);
            setScreenShot6Src(screenShot6Tab);
          } else {
            setGraphImageSrc(graphImage);
            setScreenShot1Src(screenShot1);
            setScreenShot2Src(screenShot2);
            setScreenShot3Src(screenShot3);
            setScreenShot4Src(screenShot4);
            setScreenShot5Src(screenShot5);
            setScreenShot6Src(screenShot6);
          }
        };
    
        updateImageSrc();
        window.addEventListener('resize', updateImageSrc);
    
        return () => window.removeEventListener('resize', updateImageSrc);
    }, []);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isMobile = windowWidth <= 480;
    const isTab = windowWidth <= 825 && windowWidth > 480;
    const isDesktop = windowWidth > 825;

    const getDownloadLink = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "https://apps.apple.com/in/app/hemehealth-medical-records/id1630880905";
        }

        return "https://play.google.com/store/apps/details?id=com.hemenew&pcampaignid=web_share";
    };

    return (
        <div>
            <div className="header-background">
                <div className="header-container">
                    <img src={logoWhite} className="logo-image"/>
                    <a href={getDownloadLink()} target="_blank" className="download-button">
                        Download Our App
                    </a>
                </div>
                <div className="main-content">
                    {isMobile || isTab ? (
                        <>
                        <img src={graphImageSrc} className="graph-image" alt="Graph" />
                        <div>
                            <div className="health-title">Your Health Cloud</div>
                            <div className="health-description">Take control of your health data by storing all your medical and health data in a single cloud store - fully owned and controlled by you.</div>
                            {/* <div style={{ 
                                display: 'flex', 
                                justifyContent: 'flex-start', 
                                flexDirection: 'row', 
                                alignItems: 'flex-start',
                                gap: '16px'
                            }}
                            >
                                <div style={{ 
                                padding: '10px 16px', 
                                borderRadius: '5px', 
                                backgroundColor: '#7ABA56', 
                                color: '#FFFFFF', 
                                fontSize: '16px',
                                cursor: 'pointer' 
                                }}>
                                    For Individuals
                                </div>
                                <div style={{ 
                                    padding: '10px 16px', 
                                    borderRadius: '5px', 
                                    backgroundColor: '#093A78', 
                                    color: '#FFFFFF', 
                                    fontSize: '16px',
                                    cursor: 'pointer' 
                                }}>
                                    For Organisations
                                </div>
                            </div> */}
                        </div>
                        </>
                    ):
                    (
                        <>
                        <div>
                            <div className="health-title">Your Health Cloud</div>
                            <div className="health-description">Take control of your health data by storing all your medical and health data in a single cloud store - fully owned and controlled by you.</div>
                            {/* <div style={{ 
                                display: 'flex', 
                                justifyContent: 'flex-start', 
                                flexDirection: 'row', 
                                alignItems: 'flex-start',
                                gap: '16px'
                            }}
                            >
                                <div style={{ 
                                padding: '10px 16px', 
                                borderRadius: '5px', 
                                backgroundColor: '#7ABA56', 
                                color: '#FFFFFF', 
                                fontSize: '16px',
                                cursor: 'pointer' 
                                }}>
                                    For Individuals
                                </div>
                                <div style={{ 
                                    padding: '10px 16px', 
                                    borderRadius: '5px', 
                                    backgroundColor: '#093A78', 
                                    color: '#FFFFFF', 
                                    fontSize: '16px',
                                    cursor: 'pointer' 
                                }}>
                                    For Organisations
                                </div>
                            </div> */}
                        </div>
                        <img src={graphImageSrc} className="graph-image" alt="Graph" />
                        </>
                    )}
                    
                </div>
            </div>
            {isTab ? (
                <div className="stats-header">
                    <div className="lab-container">
                        <span className="lab-inner-container">
                            <img src={labIcon} className="lab-icon" />
                            <div className="number-text">325</div>
                        </span>
                        <div className="stats-text">Labs onboarded</div>
                    </div>
                    <div className="doctor-container">
                        <span className="doctor-inner-container">
                            <img src={doctorIcon} className="doctor-icon"/>
                            <div className="number-text">250+</div>
                        </span>
                        <div className="stats-text">Labs using</div>
                    </div>
                    <div className="users-container">
                        <span className="users-inner-container">
                            <img src={patientIcon} className="users-icon"/>
                            <div className="number-text">50,000+</div>
                        </span>
                        <div className="stats-text">Users</div>
                    </div>
                </div>
            ) : (
                <div className="stats-header">
                    <div className="lab-container">
                        <img src={labIcon} className="lab-icon" />
                        <span className="lab-inner-container">
                            <div className="number-text">325</div>
                            <div className="stats-text">Labs onboarded</div>
                        </span>
                    </div>
                    <div className="doctor-container">
                        <img src={doctorIcon} className="doctor-icon"/>
                        <span className="doctor-inner-container">
                            <div className="number-text">250+</div>
                            <div className="stats-text">Labs using</div>
                        </span>
                    </div>
                    <div className="users-container">
                        <img src={patientIcon} className="users-icon"/>
                        <span className="users-inner-container">
                            <div className="number-text">50,000+</div>
                            <div className="stats-text">Users</div>
                        </span>
                    </div>
                </div>
            )}
            {isMobile ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '30px', backgroundColor: '#D7EFF7', padding: '36px 30px 0 24px' }}>
                <div style={{ fontSize: '24px', color: '#000000', fontWeight: 'bolder', textAlign: 'center'}}>Transferring Trust: Complete Control Over Your Health Data</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '20px' }}>
                    <div style={{ fontSize: '16px', color: '#808080', marginBottom: '28px' }}>Gain full ownership of your health data with Heme HealthCloud. Transfer and manage your data as you please - store, share, or export anytime, anywhere.</div>
                    <img src={screenShot1Src} className="screenshot-image" alt="screenshot-1" />
                </div>
            </div>
            ) : (
            <div className="screenshot-container">
                <div>
                    <div className="screenshot-title">Transferring Trust: Complete Control Over Your Health Data</div>
                    <div className="screenshot-description">Gain full ownership of your health data with Heme HealthCloud. Transfer and manage your data as you please - store, share, or export anytime, anywhere.</div>
                </div>
                <img src={screenShot1Src} className="screenshot-image" alt="screenshot-1" />
            </div>
            )}
            
            {isMobile ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '30px', backgroundColor: '#EBF7FB', padding: '36px 24px 0 30px'}}>
                <div style={{ fontSize: '24px', color: '#000000', fontWeight: 'bolder', textAlign: 'center'}}>Sharing: Universal Access to Your Data</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '20px' }}>
                    <img src={screenShot2Src} className="screenshot-image" alt="screenshot-2" />
                    <div style={{ fontSize: '16px', color: '#808080', marginBottom: '28px' }}>Effortlessly share your health data with anyone. From detailed visualizations to medical imagery (MRI, CT, PET, etc.), provide clinicians with essential information for second opinions or share your blood pressure graph with caregivers - all from one centralized location.</div>
                </div>
            </div>
            ) : (
            <div className="screenshot-container2">
                <img src={screenShot2Src} className="screenshot-image" alt="screenshot-2" />
                <div>
                    <div className="screenshot-title">Sharing: Universal Access to Your Data</div>
                    <div className="screenshot-description">Effortlessly share your health data with anyone. From detailed visualizations to medical imagery (MRI, CT, PET, etc.), provide clinicians with essential information for second opinions or share your blood pressure graph with caregivers - all from one centralized location.</div>
                </div>
            </div>
            )}

            {isMobile ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '30px', backgroundColor: '#D7EFF7', padding: '36px 30px 0 24px' }}>
                <div style={{ fontSize: '24px', color: '#000000', fontWeight: 'bolder', textAlign: 'center'}}>Real-Time Updates for Caregivers</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '20px' }}>
                    <div style={{ fontSize: '16px', color: '#808080', marginBottom: '28px' }}>Instantly update all your caregivers with the latest health data, maintaining full control over what is shared.</div>
                    <img src={screenShot3Src} className="screenshot-image" alt="screenshot-3" />
                </div>
            </div>
            ) : (
            <div className="screenshot-container">
                <div>
                    <div className="screenshot-title">Real-Time Updates for Caregivers</div>
                    <div className="screenshot-description">Instantly update all your caregivers with the latest health data, maintaining full control over what is shared.</div>
                </div>
                <img src={screenShot3Src} className="screenshot-image" alt="screenshot-3" />
            </div>
            )}

            {isMobile ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '30px', backgroundColor: '#EBF7FB', padding: '36px 24px 0 30px'}}>
                <div style={{ fontSize: '24px', color: '#000000', fontWeight: 'bolder', textAlign: 'center'}}>Enrich Your Health Records</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '20px' }}>
                    <img src={screenShot4Src} className="screenshot-image" alt="screenshot-4" />
                    <div style={{ fontSize: '16px', color: '#808080', marginBottom: '28px' }}>Enhance your health records with ease. Add photos, videos, and notes to create a comprehensive and instantly accessible log for your caregivers.</div>
                </div>
            </div>
            ) : (
            <div className="screenshot-container2">
                <img src={screenShot4Src} className="screenshot-image" alt="screenshot-4" />
                <div>
                    <div className="screenshot-title">Enrich Your Health Records</div>
                    <div className="screenshot-description">Enhance your health records with ease. Add photos, videos, and notes to create a comprehensive and instantly accessible log for your caregivers.</div>
                </div>
            </div>
            )}

            {isMobile ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '30px', backgroundColor: '#D7EFF7', padding: '36px 30px 0 24px' }}>
                <div style={{ fontSize: '24px', color: '#000000', fontWeight: 'bolder', textAlign: 'center'}}>Demystifying Medical Reports</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '20px' }}>
                    <div style={{ fontSize: '16px', color: '#808080', marginBottom: '28px' }}>Confused by medical jargon? Utilize our latest AI tools for a clear understanding of your health reports. Also, interact with our experimental chatbot for guidance (available in select locations).</div>
                    <img src={screenShot5Src} className="screenshot-image" alt="screenshot-5" />
                </div>
            </div>
            ) : (
            <div className="screenshot-container">
                <div>
                    <div className="screenshot-title">Demystifying Medical Reports</div>
                    <div className="screenshot-description">Confused by medical jargon? Utilize our latest AI tools for a clear understanding of your health reports. Also, interact with our experimental chatbot for guidance (available in select locations).</div>
                </div>
                <img src={screenShot5Src} className="screenshot-image" alt="screenshot-5" />
            </div>
            )}
            {isMobile ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '30px', backgroundColor: '#EBF7FB', padding: '36px 24px 0 30px'}}>
                <div style={{ fontSize: '24px', color: '#000000', fontWeight: 'bolder', textAlign: 'center'}}>Get personalized insights into your health data with AI-powered tools</div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '20px' }}>
                    <img src={screenShot6Src} className="screenshot-image" alt="screenshot-6" />
                    <div style={{ fontSize: '16px', color: '#808080', marginBottom: '28px' }}>Artificial Intelligence helps to understand what your medical data & reports mean​</div>
                </div>
            </div>
            ) : (
            <div className="screenshot-container2">
                <img src={screenShot6Src} className="screenshot-image" alt="screenshot-6" />
                <div>
                    <div className="screenshot-title">Get personalized insights into your health data with AI-powered tools</div>
                    <div className="screenshot-description">Artificial Intelligence helps to understand what your medical data & reports mean​</div>
                </div>
            </div>
            )}
            {isMobile && (
                <>
                <div style={{ backgroundColor: '#FFFFFF', padding: '30px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ fontSize: '30px', color: '#0099CC'}}>Partner Labs</div>
                    <div style={{ marginBottom: '20px' }}>
                        <img src={logo1} style={{ width: '150px', height: 'auto', marginRight: '16px' }}/>
                        <img src={logo2} style={{ width: '150px', height: 'auto'}}/>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <img src={logo3} style={{ width: '150px', height: 'auto', marginRight: '16px' }}/>
                        <img src={logo4} style={{ width: '150px', height: 'auto' }}/>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <img src={logo5} style={{ width: '150px', height: 'auto', marginRight: '16px' }}/>
                        <img src={logo6} style={{ width: '150px', height: 'auto'}}/>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <img src={logo7} style={{ width: '150px', height: 'auto', marginRight: '16px' }}/>
                        <img src={logo8} style={{ width: '150px', height: 'auto' }}/>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <img src={logo9} style={{ width: '150px', height: 'auto', marginRight: '16px' }}/>
                        <img src={logo10} style={{ width: '150px', height: 'auto'}}/>
                    </div>
                </div>
                </>
            )}
            {isTab && (
                <>
                <div style={{ backgroundColor: '#FFFFFF', padding: '30px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ fontSize: '30px', color: '#0099CC'}}>Partner Labs</div>
                    <div style={{ marginBottom: '20px' }}>
                        <img src={logo1} style={{ width: '150px', height: 'auto', marginRight: '36px' }}/>
                        <img src={logo2} style={{ width: '150px', height: 'auto', marginRight: '36px' }}/>
                        <img src={logo3} style={{ width: '150px', height: 'auto'}}/>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <img src={logo4} style={{ width: '150px', height: 'auto', marginRight: '36px' }}/>
                        <img src={logo5} style={{ width: '150px', height: 'auto', marginRight: '36px' }}/>
                        <img src={logo6} style={{ width: '150px', height: 'auto' }}/>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <img src={logo7} style={{ width: '150px', height: 'auto', marginRight: '36px' }}/>
                        <img src={logo8} style={{ width: '150px', height: 'auto', marginRight: '36px' }}/>
                        <img src={logo9} style={{ width: '150px', height: 'auto'}}/>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <img src={logo10} style={{ width: '150px', height: 'auto' }}/>
                    </div>
                </div>
                </>
            )}
            {isDesktop && (
                <>
                <div style={{ backgroundColor: '#FFFFFF', padding: '64px 120px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ fontSize: '30px', color: '#0099CC' }}>Partner Labs</div>
                    <div style={{ marginBottom: '30px' }}>
                        <img src={logo1} style={{ width: '220px', height: 'auto', marginRight: '60px' }}/>
                        <img src={logo4} style={{ width: '220px', height: 'auto', marginRight: '60px' }}/>
                        <img src={logo3} style={{ width: '220px', height: 'auto', marginRight: '60px' }}/>
                        <img src={logo5} style={{ width: '220px', height: 'auto' }}/>
                    </div>
                    <div style={{ marginBottom: '30px' }}>
                        <img src={logo6} style={{ width: '220px', height: 'auto', marginRight: '60px' }}/>
                        <img src={logo7} style={{ width: '220px', height: 'auto', marginRight: '60px' }}/>
                        <img src={logo8} style={{ width: '220px', height: 'auto', marginRight: '60px' }}/>
                        <img src={logo10} style={{ width: '220px', height: 'auto' }}/>
                    </div>
                    <div>
                        <img src={logo2} style={{ width: '220px', height: 'auto', marginRight: '60px' }}/>
                        <img src={logo9} style={{ width: '220px', height: 'auto' }}/>
                    </div>    
                </div>
                </>
            )}
            <div className="app-download-container">
                <div className="download-text">Download HemeCloud app to securely store, access and analyse your laboratory reports, for FREE!</div>             
                {isMobile ? (
                <>
                    <div className="download-buttons-1">
                        <a href="https://play.google.com/store/apps/details?id=com.hemenew&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                            <img src={playStore} style={{ width: '180px', height: '56px' }}/>
                        </a>
                    </div>
                    <div className="download-buttons">
                        <a href="https://apps.apple.com/in/app/hemehealth-medical-records/id1630880905" target="_blank" rel="noopener noreferrer">
                            <img src={appStore} style={{ width: '180px', height: '56px' }}/>
                        </a>
                    </div>
                </>
                ) : (
                <>
                    <div className="download-buttons">
                        <a href="https://play.google.com/store/apps/details?id=com.hemenew&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                            <img src={playStore} style={{ width: '180px', height: '56px', marginRight: '16px' }}/>
                        </a>
                        <a href="https://apps.apple.com/in/app/hemehealth-medical-records/id1630880905" target="_blank" rel="noopener noreferrer">
                            <img src={appStore} style={{ width: '180px', height: '56px' }}/>
                        </a>
                    </div>
                </>
                )}                
                <div>
                    <span style={{ marginRight: '16px', fontSize: '15px', color: '#D7EFF7' }}>Follow us on</span>
                    <a href="https://www.facebook.com/HemeHealth.io" target="_blank" rel="noopener noreferrer">
                        <img src={facebookLogo} style={{ width: '24px', height: '24px', marginRight: '17px' }} alt="Facebook"/>
                    </a>
                    <a href="https://twitter.com/hemehealth" target="_blank" rel="noopener noreferrer">
                        <img src={twitterLogo} style={{ width: '24px', height: '24px', marginRight: '17px' }} alt="Twitter"/>
                    </a>
                    <a href="https://in.linkedin.com/company/heme-health" target="_blank" rel="noopener noreferrer">
                        <img src={linkedLogo} style={{ width: '24px', height: '24px' }} alt="LinkedIn"/>
                    </a>
                </div>
            </div>
            <Footer />  
        </div>   
    );
}