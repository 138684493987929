  import React from "react";
  import { Link } from "react-router-dom";
  import footerImage from "../images/bottom-background.png";


  const toggleMenu =()=> {
    
    document.getElementById('isToggle').classList.toggle('open');
    var isOpen = document.getElementById('navigation')
    if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
    } else {
        isOpen.style.display = "block";
    }
  };
  export default function Privacy() {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
      <div className="App">
        <header id="topnav" className="defaultscroll sticky">
            <div className="container" id="menudraw">
                
              <a className="logo" href="/"> 
                <img src="asset/images/logo.svg"  className="logo-light-mode" id="logo-image"  alt=""/>
                <img src="asset/images/logo.svg" className="logo-dark-mode"  alt=""/>
              </a>
              <div className="menu-extras">
                <div className="menu-item">
                  <a className="navbar-toggle" id="isToggle" onClick={()=>toggleMenu()}>
                    <img className="mt-4" src="asset/images/icon/hemburger-icon.svg" style={{}}  />
                  </a>
                </div>
              </div>
              <div id="navigation">
                <ul className="navigation-menu">
                    <li className="parent-parent-menu-item">
                      {/* <a className="sub-menu-item active" style= {{background: "linear-gradient(to right,#2477B3, #0099CC)",paddingLeft:30,paddingRight:30,borderRadius:45,height:10,paddingTop:10,paddingBottom:33,margin:15,color:"#fff",}}>For Patients</a> */}
                      <Link to="/" className="sub-menu-item" onClick={scrollToTop}>For Patients</Link>
                      </li>
                    <li className="parent-parent-menu-item" style={{}}>
                      {/* <a className="sub-menu-item" href="/ForLabs" >For Labs</a> */}
                      <Link to="/ForLabs" className="sub-menu-item" onClick={scrollToTop}>For Labs</Link>
                      </li>
                </ul>  
              </div>     
            </div>
          </header>
        <section className="section " id="">
          <div className="container">
            <div className="row justify-content-left">
              <div className="col-12 text-left">
                <section id="terms-section">
                  </section>
                <h4 className="title mb-4" style={{textAlign: 'center'}}><u>HEME APP TERMS AND CONDITIONS (FOR INDIVIDUAL USERS)</u></h4>
                <h6 className="title mb-4"><u>BACKGROUND:</u></h6>
                <p className="text-muted mt-3 mb-0">
                  Heme Health Private Limited ("Company") has developed the Heme App which shall assist individuals to store and access their medical information in a centralized application (defined below) ("App"). 
                </p><br/>
                <h6 className="title mb-4"><u>INTRODUCTION:</u></h6>
                <ul>
                  <li className="text-muted">These Terms and Conditions ("T&Cs") shall constitute an electronic Medical Record within the meaning of the Information Technology Act, 2000 and the rules made thereunder and under all other applicable laws as amended from time to time. </li>
                  <li className="text-muted">These T&Cs set forth the standards of use of the App and it shall form your legally binding agreement with us once accepted (the “Agreement”). The words (“You”) or (“User”) or (“Your”) as used in these T&Cs mean to include and refer to all individuals accessing or using the App for any reason.</li>
                </ul>
                <h6 className="title mb-4"><u>DEFINITIONS:</u></h6>
                <ul>
                  <li className="text-muted">“Heme App” or “App” is our mobile application available for free on iOS and/or Android, including elements of a range of Heme products, the scope of which may change from time to time.</li>
                  <li className="text-muted">“Lab” is the pathology diagnostic center the User has contacted and availed services of detection and diagnostic screening for any disease or illness.</li>
                  <li className="text-muted">"Medical Records" is the electronic medical report generated by the Lab based on the testing samples provided by User to the Lab. The Medical Records shall be uploaded on the App in order for the patient to view, store and download the relevant Medical  Records.</li>
                  <li className="text-muted">"Services" is collectively the various services offered by the Company and other services may be introduced from time to time on the App.</li>
                </ul>
                <h6 className="title mb-4"><u>ELIGIBILITY:</u></h6>
                <ul>
                  <li className="text-muted">You represent and warrant that:</li>
                  <li className="text-muted">you are a resident of India;</li>
                  <li className="text-muted">you are 18 years or older, or at the legal age to form a binding contract in the jurisdiction of your residence;</li>
                  <li className="text-muted">you have the right and authority to legally bind yourself or your family members or your company, as applicable;</li>
                  <li className="text-muted">the information provided by you are either your property or you are an authorized person for the same;</li>
                  <li className="text-muted">you acknowledge and confirm that the Lab is responsible for the generation of the Medical Report and its accuracy. </li>
                  <li className="text-muted">You are responsible for all the information provided by You on the App and all the information provided by You to the Lab at the time of using the diagnostic service from the Lab.</li>
                </ul>
                <h6 className="title mb-4"><u>SERVICES:</u></h6>
                <ul>
                  <li className="text-muted">You represent and warrant that:
                  <ul>
                    <li className="text-muted">Store medical records, specifically, pathology blood tests. (Records section)</li>
                    <li className="text-muted">Log their symptoms by adding various different types of information, including notes, photos, blood sugar measurement, pain measurements and many more (Track section)</li>
                    <li className="text-muted">Chat with 'HemeBot', AI health assistant powered by Large Language models to answers health related questions (This is not to be construed as clinical advice, and AI may be generating incorrect response - it is explicitly labelled as such).</li>
                  </ul>
                  </li>
                </ul>
                <h6 className="title mb-4"><u>REGISTRATION PROCESS:</u></h6>
                <ul>
                  <li className="text-muted">You must complete the registration process as described below to access the App and our Services (the “Registration Process”):
                    <ul>
                      <li className="text-muted">You must register using your own  phone number;</li>
                      <li className="text-muted">Registration is a pre-requisite for You to create an account with Heme (the “Account”);</li>
                      <li className="text-muted">The Registration Process is complete only after You agree to be bound by these T&Cs </li>
                      <li className="text-muted">You further understand and agree that the Company may screen and verify the information provided by You at its sole discretion, and modify the information requested during the Registration Process and may ask for further information even after Registration Process; and</li>
                      <li className="text-muted">The Company may, in its sole discretion, close the Account if any information provided by You during the Registration Process is found to be false, incomplete or insufficient.</li>
                    </ul>
                  </li>
                </ul>
                <h6 className="title mb-4"><u>USE OF THE APP:</u></h6>
                <ul>
                  <li className="text-muted">Please read these T&Cs carefully before you download, install or use the App. Your download, installation or use of the App constitutes your acceptance of these T&Cs, which takes effect on the date on which you download, install or use the App. If you do not agree with these T&Cs, you should cease downloading, installing or using the app immediately.</li>
                  <li className="text-muted">By indicating Your acceptance of these T&Cs, You hereby agree, represent and warrant the following:
                    <ul>
                      <li className="text-muted">You have the right and authority to legally access the App;</li>
                      <li className="text-muted">You are consenting to be bound by these T&Cs privacy policy and other agreements of use as may be indicated by the Company from time to time and/or under applicable law;</li>
                      <li className="text-muted">By accessing the Medical Records in the App, you consent that we have the right to view the data (including sensitive personal data or information) available in Medical Records All updates to the data (including sensitive personal data or information) will also be visible to us but we shall not be able to edit Medical Records;</li>
                      <li className="text-muted">We may process and display data (including sensitive personal data or information) in the Medical Records to support personalized Medical Records management for our Users. We also process data for analysis without disclosing individual identity, medical analysis and research as per our privacy policy; and </li>
                      <li className="text-muted">You specifically understand, acknowledge and consent to, during or after their use of the Services on the App, that the Company has your consent to use their data/information as a part of the aggregate, anonymized database as allowed under prevailing Indian laws and that the Company may be compensated for such distribution.</li>
                      <li className="text-muted">You specifically understand that use of HemeBot, which is based on Large Language Models, may generate incorrect or inaccurate responses. It is NOT be used under any circumstances as clinical advice, nor is it advertised as such.</li>
                      <li className="text-muted">You specifically understand that any information provided by HemeBot is to be validated by a qualified clinical practitioner before use.</li>
                      <li className="text-muted">You must not share any personal information with HemeBot, which is operated using a third-party AI model, and not hosted by Company</li>
                    </ul>
                  </li>
                  <li className="text-muted">You agree that while utilizing the App, you shall not do any of the following:
                    <ul>
                      <li className="text-muted">reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the App;</li>
                      <li className="text-muted">defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others;</li>
                      <li className="text-muted">publish, post, upload, distribute or disseminate any inappropriate, blasphemous, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;</li>
                      <li className="text-muted">upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of our website or another's computer or device;</li>
                      <li className="text-muted">conduct or forward surveys, contests, pyramid schemes or chain letters;</li>
                      <li className="text-muted">download any file posted by another user of a service that you know, or reasonably should know, cannot be legally distributed or used in such manner;</li>
                      <li className="text-muted">falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;</li>
                      <li className="text-muted">violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;</li>
                      <li className="text-muted">violate any applicable laws or regulations in force in or outside India; and</li>
                      <li className="text-muted">violate any of these T&Cs for the use of the App.</li>
                    </ul>
                  </li>
                  <li className="text-muted">We hereby grant you a non-exclusive, irrevocable, personal, limited license to access and use the App in accordance with the T&Cs.</li>
                  <li className="text-muted">Unless otherwise explicitly mentioned in the T&Cs, You have no rights on the App except the right to use our App in accordance with these T&Cs.</li>
                  <li className="text-muted">Any unauthorized use of any of the materials or information on this App shall be a breach of our intellectual property rights in and to the App owned by or licensed to the Company.</li>
                </ul>
                <h6 className="title mb-4"><u>EFFECTIVE DATE:</u></h6>
                <ul>
                    <li className="text-muted">This Agreement will come into effect from the date  You enter your mobile number and continue to login App and this action constitutes your agreement to be governed by these T&Cs and any other document that is expressly incorporated in the T&Cs (“Effective Date”).</li>
                </ul>
                <h6 className="title mb-4"><u>USER DATA:</u></h6>
                <ul>
                  <li className="text-muted">You also agree not to create user accounts by automated means or under false or fraudulent pretenses and/or create or transmit unwanted electronic communications such as "spam" to other users of the App and/or otherwise interfere with a user's enjoyment of the App.</li>
                  <li className="text-muted">You hereby explicitly provide your consent to the Company, as may be required under the relevant applicable laws: 
                    <ul>
                      <li className="text-muted">to share such data and personal information with third parties for marketing and advertising purposes; and</li>
                      <li className="text-muted">to permit the Company to track the activities of the user on the App and to receive transactional and promotional updates and any other form of electronic communications including but not limited to by way of pop-ups, notifications, e-mails, WhatsApp notifications, messages, etc. from the Company and other third parties.</li>
                    </ul>
                  </li>
                  <li className="text-muted">The Company will not share, sell, distribute or lease a User’s/Customer’s personal information to third parties unless the Company has the User’s specific permission or is required by law to do so.</li>
                  <li className="text-muted">You acknowledge and understand that the Company and the T&Cs shall always be in applicable rules and regulations in relation to storage of electronic medical records including the Electronic Health Record Standards for India, 2016.</li>
                  <li className="text-muted">You further undertake and acknowledge that the Company shall be the owner of and shall retain all intellectual property rights over the App, and any other content, information and documentation disclosed in connection with the User’s access to the App.</li>
                </ul>
                <h6 className="title mb-4"><u>ACCURACY OF THE MEDICAL REPORT:</u></h6>
                <ul>
                  <li className="text-muted">The User acknowledges and understands that the medical report is generated by the Lab on the basis of the information and the medical sample provided by You. Further, the User confirms and acknowledges that the Lab shall always be responsible for the accuracy and/or correctness of the medical report generated by it.</li>
                </ul>
                <h6 className="title mb-4"><u>INTELLECTUAL PROPERTY RIGHTS:</u></h6>
                <ul>
                  <li className="text-muted">Unless otherwise indicated, the Company has the sole and exclusive right over all the intellectual property rights in and to the App. You acknowledge that the App and any underlying technology or software used in connection with rendering the Services on the App are proprietary information owned by the Company, except where it is indicated otherwise.</li>
                  <li className="text-muted">You shall not reproduce or distribute any content from the App without obtaining prior written authorization from the Company.</li>
                  <li className="text-muted">If the Company believes in good faith any material on its App has been illegally copied or is posted, uploaded or made accessible through the App or Services, it may send an legal notice and remove access to the App for such offenders. Such legal notice sent by the Company is without prejudice to its rights to proceed against the offender by filing a lawsuit in the appropriate court of law on such grounds as the Company may deem fit.</li>
                </ul>
                <h6 className="title mb-4"><u>LIMIATION OF LIABILITY:</u></h6>
                <ul>
                  <li className="text-muted" >The Company shall not be liable for any loss, damage or destruction suffered on account of any delay or disruption in the App and/or inability of the User to access or enter the relevant details on account of occurrence of any force majeure event including but not limited to unavailability of any communication system, sabotage, fire, flood, explosion, accident, acts of God, civil commotion, pandemic (including but not limited to Covid-19), strikes or industrial action of any kind, riots, insurrection, war, pandemic, the use or operation, as a means for inflicting harm, of any computer, computer system, computer software, computer software programme, malicious code, computer virus or process or any other electronic system or terrorism.</li>
                  <li className="text-muted" >The User acknowledges and confirms that the Company does not make any warranty, express or implied, with respect to the Services, the App or the results thereof. The Company shall not be liable for the accuracy, veracity and/or correctness of the Medical Records generated by the Lab.</li>
                  <li className="text-muted" >The User acknowledges that use of Hemebot AI technology services within the App, may generate incorrect or inaccurate response. UNDER NO CIRCUMSTANCES IS IT MEANT TO BE CLINICAL ADVICE. In NO EVENT, SHALL COMPANY BE LIABLE FOR ANY DAMAGES that may arise due to INCORRECT USE OF THIS TECHNOLOGY.</li>
                  <li className="text-muted" >IN NO EVENT SHALL THE COMPANY BE LIABLE FOR CONSEQUENTIAL, INCIDENTAL, SPECIAL OR INDIRECT DAMAGES; OR FOR ECONOMIC LOSS (INCLUDING LOSS OF PROFITS, BUSINESS, REVENUE, GOODWILL OR ANTICIPATED SAVINGS); OR FOR ACTS OF NEGLIGENCE THAT ARE NOT INTENTIONAL OR RECKLESS IN NATURE, REGARDLESS OF WHETHER IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; OR FOR LOSS ARISING FROM ANY CLAIM MADE AGAINST THE USER BY ANY OTHER PERSON; OR FOR LOSS OR DAMAGE ARISING FROM THE USER’S FAILURE TO FULFIL ITS RESPONSIBILITIES OR ANY MATTER UNDER THE CONTROL OF THE USER. IN NO EVENT SHALL THE COMPANY BE LIABLE FOR LOSSES OR FAILURES CAUSED (i) BY THE USE OF INTEGRATED SERVICES OR (ii) BY THEIR PROVIDERS.</li>
                </ul>
                <h6 className="title mb-4"><u>MAINTENACE:</u></h6>
                <ul>
                  <li className="text-muted">The Company may at its sole discretion, without assigning any reason whatsoever at any time, deactivate or/and suspend Your access to the App (as the case may be) without giving any prior notice to carry out system maintenance and/or upgrading and/or testing and/or repairs and/or other related work. </li>
                </ul>
                <h6 className="title mb-4"><u>TERMINATION:</u></h6>
                <ul>
                  <li className="text-muted">We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these T&Cs.</li>
                  <li className="text-muted">Notwithstanding anything to the contrary in these T&Cs, the Company has the right to immediately terminate, suspend, or amend this Agreement, without liability:
                    <ol type="a">
                      <li className="text-muted">to comply with any order issued or proposed to be issued by any governmental agency;</li>
                      <li className="text-muted">to comply with any provision of law; or </li>
                      <li className="text-muted">if performance of any term of this T&Cs by either party would cause it to be in violation of law.</li>
                    </ol>
                  </li>
                  <li className="text-muted">Upon termination of this Agreement, You agree to cease to use our App and that the Company may terminate your access to it effective immediately upon termination.</li>
                </ul>
                <h6 className="title mb-4"><u>GOVERNING LAW AND DISPUTE RESOLUTION:</u></h6>
                <ul>
                  <li className="text-muted">In the event of any dispute or difference arising under this Agreement, the parties shall first endeavor to  reach an amicable resolution. In the event, the parties fail to arrive at an amicable resolution within 30 days of the dispute having arisen, the matter shall be  resolved, in full and final settlement, by arbitration under a sole arbitrator appointed by the mutual consent of the Parties, failing which such sole arbitrator shall be appointed in terms the Arbitration and Conciliation Act 1996 (as amended from time to time). The seat and venue of the arbitration proceedings shall be Mumbai and the arbitration shall be conducted in the English language. Subject to the above, the courts of Mumbai shall have the sole and exclusive jurisdiction to resolve any disputes or disagreements that arise in relation to this Agreement.</li>
                </ul>
                <h6 className="title mb-4"><u>DISCLAIMER:</u></h6>
                <ul>
                  <li className="text-muted">All information, content, materials, software and other services included on or otherwise made available to the User on the App is provided on an "as is" basis, unless otherwise specified. The Company makes no representations or warranties of any kind, expressed or implied, as to the operation of the Services, or the information, content, materials, software or other services included or otherwise made available through the App or the results thereof. The Company to the fullest extent permissible under applicable law, disclaims all warranties, express, statutory, or implied and does not warrant the accuracy or completeness of the information, text, graphics, links or other items contained within the App.</li>
                  <li className="text-muted">The Company shall not accept any liability for any damage to Your computer system or loss of data caused by using the App or claimed to be arising on account of usage of the App.</li>
                </ul>
                <h6 className="title mb-4"><u>MISCELLANEOUS:</u></h6>
                <ul>
                  <li className="text-muted">The clause headings contained in these T&Cs are for reference purposes only and shall not affect in any way the meaning or interpretation of these T&Cs.</li>
                  <li className="text-muted">You understand and agree that these T&Cs can be modified by the Company at its sole discretion, at any time without prior notice, and such modifications will be effective upon such new terms and/or upon implementation of the new changes to the App. You agree that the Company shall not be liable for any loss or costs suffered by You due to your failure to review such modified terms of use.</li>
                  <li className="text-muted">These T&Cs will be binding upon and inure to the benefit of the parties and their respective successors and assignees.</li>
                  <li className="text-muted">If any provision of this T&Cs is held by a court of competent jurisdiction to be illegal, invalid or unenforceable, that provision shall be severed, and the remaining provisions shall remain in full force and effect.</li>
                </ul><br/>
                <h4 className="title mb-4" style={{textAlign: 'center'}}><u>HEME HEALTH WEBSITE TERMS & CONDITIONS</u></h4>
                <h6 className="title mb-4"><u>INTRODUCTION</u></h6>
                <ul>
                  <li className="text-muted">These Terms and Conditions ("Terms") govern your use of the HemeHealth website ("Website") and HemeHealth App ("App") operated by HemeHealth ("Company"). By accessing or using the Website or the App, you agree to be bound by these Terms.</li>
                </ul>
                <h6 className="title mb-4"><u>USER ELIGILIBITY</u></h6>
                <ul>
                  <li className="text-muted">The Website and the App are intended for use by individuals who are at least 18 years old or have reached the age of majority in their jurisdiction and are based in India. By using the Website or the App, you represent and warrant that you are of legal age to form a binding contract with the Company.</li>
                </ul>
                <h6 className="title mb-4"><u>USER CONDUCT</u></h6>
                <ul>
                  <li className="text-muted">You agree to use the Website and the App for lawful purposes only and not to use the Website or the App in any way that violates any applicable laws or regulations. You also agree not to:
                    <ul>
                      <li className="text-muted">Use the Website or the App in any manner that could disable, overburden, or impair the Website or the App or interfere with any other party's use of the Website or the App.</li>
                      <li className="text-muted">Use any robot, spider, or other automatic device, process, or means to access the Website or the App for any purpose, including monitoring or copying any of the material on the Website or the App.</li>
                      <li className="text-muted">Use any manual process to monitor or copy any of the material on the Website or the App or for any other unauthorized purpose without our prior written consent.</li>
                      <li className="text-muted">Use any device, software, or routine that interferes with the proper working of the Website or the App.</li>
                      <li className="text-muted">Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
                      <li className="text-muted">Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website or the App, the server on which the Website or the App is stored, or any server, computer, or database connected to the Website or the App.</li>
                    </ul>
                  </li>
                </ul>
                <h6 className="title mb-4"><u>INTELLECTUAL PROPERTY RIGHTS</u></h6>
                <ul>
                  <li className="text-muted">The Website, the App, and all of their contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio) are owned by the Company, its licensors, or other providers of such material and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</li>
                  <li className="text-muted">You may use the Website and the App only for your personal and non-commercial use. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Website or the App except as generally permitted by law.</li>
                </ul><br/>
                <section id="privacy-section">
                  <h4 className="title mb-4" style={{textAlign: 'center'}}><u>PRIVACY POLICY</u></h4>
                </section>
                <p className="text-muted mt-3 mb-0">The Company respects your privacy and has developed a Privacy Policy that explains how we collect, use, and disclose your personal information. By using the Website or the App, you consent to our collection, use, and disclosure of your personal information in accordance with our Privacy Policy.</p>
                <p className="text-muted mt-3 mb-0">This Privacy Statement outlines the way in which Blenheim Chalcot IT Services India Private Limited uses and processes the information relating to you, the user of the Heme App (hereinafter referred to as “User” or “you” or similar). By using this Heme app, the User consents to the use (including transfer) of information as described below.</p>
                <p className="text-muted mt-3 mb-0">This policy applies to Visitors who are located in India, and is in compliance with the Information Technology Laws, 2000. The Policy will articulate the reason for collecting the data, the manner in which the data will be processed and used, the legal basis for processing the data and how long will the data be kept.</p>
                <br/><ol>
                  <li className="text-muted">WHAT INFORMATION DO WE COLLECT?
                    <p className="text-muted mt-3 mb-0">
                      We collect the following information from visitors to our Heme App : personal information volunteered by you (via online or offline registration forms), such as name, contact details, e-mail address, telephone/mobile number, etc. and non personal information such as IP addresses, information relating to what pages have been viewed (e.g. using cookies as described further below) and whether you have chosen to receive future communications from Heme App and Blenheim Chalcot India Private Limited.
                      <br/>Collection, use and disclosure of information which has been designated as Personal Information under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011.
                      <br/>On some of our web pages, you may be able to enter contests, vote in polls or otherwise express an opinion, or subscribe to one of our services such as our newsletters, or participate in one of our online forums or communities or otherwise voluntarily provide information to us. In connection with certain online/offline competitions and contests, you may be invited to submit personal information that we will collect only when you voluntarily provide it.
                    </p>
                  </li><br/>
                  <li className="text-muted">THE PURPOSE OF COLLECTING YOUR INFORMATION
                    <p className="text-muted mt-3 mb-0">
                      The legal basis for the collection and processing of all the above said Information is to further our legitimate interests of:
                      <br/>conducting our business through the provision of our goods/services if any, efficiently and in a timely manner;
                      <br/>information such as IP addresses, information relating to what pages have been viewed (e.g. using cookies as described further below);
                      <br/>providing proper administration of the Heme App ;
                      <br/>using this data with Your consent to provide You with information that You request from us.
                      <br/>monitoring and improving our Heme App and goods/services.
                    </p>
                  </li><br/>
                  <li className="text-muted">HOW DO WE USE YOUR INFORMATION?
                    <p className="text-muted mt-3 mb-0">
                      We use the information we collect on the Heme App to help us understand how the Heme App is being used and to allow us to communicate with you. Such information will enable us to communicate with you on any matter relating to your health, [including the provision by us or selected third parties of relevant information, goods and/or services in which we think you may be interested, unless you have chosen not to receive such material], to exploit, evaluate and improve the services we offer and to send promotional offers and other communications and information that we think may be useful to you (such as special offers) push notifications, and develop and conduct targeted marketing campaigns as well as behavioural advertising. We may on occasion combine information we receive online with other records to enhance our ability to notify you of the details of heme app and our other services that we think will be of interest to you. We may use your Information to comply with our obligations under any contracts or agreements binding on you and if required by Judicial process and law enforcement.
                      <br/>In the event that the operations of Blenheim Chalcot India Private Limited are transferred to or integrated with another operation, your details may be disclosed to our advisors and transferee of the operations of Blenheim Chalcot India Private Limited.
                    </p>
                  </li><br/>
                  <li className="text-muted">HOW WE STORE YOUR INFORMATION?
                    <p className="text-muted mt-3 mb-0">
                      The Information that we collect is stored in a destination in India. It may be processed by staff operating outside India who work for us or third parties.
                      <br/>Our retention periods for personal data are based on business needs and legal requirements. We will store your Information for as long as necessary to fulfil the purposes indicated in this Policy or as otherwise permitted or required by law. Your Information may be transferred, stored, processed and used by our affiliated companies and/or non-affiliated service providers in one or more countries outside your originating country. Your payment details if collected may be transferred to and stored with our affiliated companies in order to, among other things, process your payment details and provide support services to you.
                    </p>
                  </li><br/>
                  <li className="text-muted">DISCLOSE YOUR INFORMATION
                    <p className="text-muted mt-3 mb-0">
                      We may disclose your information with trusted partners who work on our behalf or with us under confidentiality agreements. These entities may use your Personal Information for performing services, administering promotions, analyzing data and usage of the Service through the Heme App, processing payments, delivering goods/services, operating the Service or providing support and maintenance services for the same.
                      <br/>We reserve the right to use or disclose Personal Information and any other information we collect in connection with the goods/service offered through the Heme App  (a) to any successor of our business, including as a result of any merger, acquisition, asset sale or similar transaction,  (b) to any corporate affiliate of ours whose privacy practices are substantially similar to ours, (c) to any law enforcement, judicial authority, governmental or regulatory authority, to the extent required by law or legal process, or  (d) if in our reasonable discretion, such use or disclosure is necessary to enforce or protect our legal rights or to protect third parties.
                      <br/>We may make non-personally-identifiable information available to third parties for various purposes. This data maybe automatically-collected and would be analysed to create an aggregated view of the data, ensure the reported information was anonymous.
                      <br/>Except as disclosed in this Policy or as otherwise authorized by you, we will not share Your personal information with third parties for their marketing purposes. Your trust and confidence are our highest priority.
                    </p>
                  </li><br/>
                  <li className="text-muted">OTHER WEBSITES
                    <p className="text-muted mt-3 mb-0">
                      The Heme App may contain links to other web sites that are outside our control and are not covered by this Privacy Policy. If you access other websites using the links provided, the operators of these websites may collect information from you, which will be used by them in accordance with their privacy policy, which may differ from ours. Other websites may collect and treat information collected differently, so we encourage you to carefully read and review the privacy policy for each website you visit. We will not be responsible for any information that you may provide to such websites accessible through the Heme App.
                    </p>
                  </li><br/>
                  <li className="text-muted">INTERNET SECURITY
                    <p className="text-muted mt-3 mb-0">
                      We ensure that personal data of visitors on this Heme App is subject to appropriate security.
                      <br/>We may share aggregated information about visitors to this site with third parties including advertisers, business partners and sponsors, to understand the trends and patterns on the Heme App and manage and improve our business relationships. The Aggregated information does not include personally identifiable information.
                      <br/>We may share personal information with third parties to administer and process your requests. These parties shall use the information solely in conjunction with the specific services they provide for us (e.g. fulfilment of an order of a newsletter, responding to your question or comment, providing a sweepstakes prize, deployment of a consumer survey, investigation of a complaint, and so on).
                      <br/>Our agents and contractors who have access to personally identifiable information are required to protect this information in a manner that is consistent with this Privacy Policy. We reserve the right to disclose any personal information as needed if such information is requested by law enforcement agencies or if we are required to do so by law, regulation, subpoena, court order, or by a government entity. We also may disclose personal information to third parties if we have reason to believe that disclosing such information is necessary to conduct investigations of possible breaches of law, to cooperate in any legal investigation, or to identify, contact, or bring legal action against someone who may be violating the terms of use of the Heme App.
                      <br/>We reserve the right to transfer any information we have about a User in the event that we sell or transfer all or a portion of our business or assets to a third party ensuring that the third party shall have similar policy to safeguard the Information so shared. We also reserve the right to share information within Blenheim Chalcot India Private Limited and its business partners.
                      <br/>Although, we will endeavour to meet the requirements of the Internet Security policy envisaged under this section, we cannot accept any responsibility for any loss, disruption or damage to your data or your computer system that may occur whilst using material derived from this Heme App. As best practice, we also strongly recommend that you regularly run an anti-virus programme on all material downloaded from the Internet.
                    </p>
                  </li><br/>
                  <li className="text-muted">SECURITY OF PERSONAL INFORMATION
                    <p className="text-muted mt-3 mb-0">
                      We take reasonable security measures and procedures, and as specified by applicable law, to maintain appropriate physical, technical and administrative security to help prevent loss, misuse, or unauthorized access, disclosure or modification of Personal Information. While we take these reasonable efforts to safeguard your personal information, you acknowledge and agree that no system or transmission of data over the Internet or any other public network can be guaranteed to be 100% secure. You should take steps to protect against unauthorized access to your phone, among other things, and keeping your log-in and password private. We are not responsible for the unauthorized use of your information or for any lost, stolen, compromised passwords, or for any activity on your Account via unauthorized password activity.
                    </p>
                  </li><br/>
                  <li className="text-muted">INTERNATIONAL TRANSFERS OF YOUR DATA
                    <p className="text-muted mt-3 mb-0">
                      The hosting facilities for our Application are situated in India. However, Your personal data may be transferred to countries outside the territory of India or the European Economic Area (EEA).
                      <br/>The European Commission has made an "adequacy decision" with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely, the use of standard data protection clauses adopted or approved by the European Commission.
                    </p><br/>
                  </li>
                  <li className="text-muted">UPDATING YOUR INFORMATION AND OPTING OUT
                    <p className="text-muted mt-3 mb-0">
                      If any of the information that you have provided to us changes, for example, if you change your e-mail address or name or if you wish to cancel your registration, please let us know the correct details by using the ‘Contact Us’ form available at Settings - Contact Us. As a registered user on the Heme App, you can use the ‘Contact Us’ form available at Settings Contact Us to update or change your preferences, along with deleting your account and all personal information from our Heme App.
                      <br/>Please note the following:
                      <br/>Delete Account will erase all your account data from the site; this action is irrevocable.
                      <br/>Occasionally, we may send you a reminder email to change your opt-in preferences, in case you have opted out of newsletters.
                    </p>
                  </li><br/>
                  <li className="text-muted">YOUR PRIVACY RIGHTS
                    <p className="text-muted mt-3 mb-0">
                      You have many rights regarding Your personal information. These rights include the following:
                      <br/>You have the right to access and know what personal information we process and why.
                      <br/>You have the right to rectification if You believe we store wrong or incomplete information about You, such as Your name, address, etc. You have the right to request a copy and update the Personal Information that we hold about you. You may ask us to correct or remove information you think is inaccurate. If you would like to invoke this right, please email or write to us at <a href="mailto:info@hemehealth.io" style={{
                      color: "#7ABA56",
                    }}>info@hemehealth.io</a>
                      <br/>You have the right to be forgotten or the right to erasure where You shall have the right to tell us to permanently erase Your personal information from our records. You can do this if You believe there’s no longer any need for us to keep it, or, if You previously have given Your permission, You can just decide to withdraw it.
                      <br/>You have the right to restrict the processing activities in certain situations. This means we will continue to store Your information, but we will temporarily stop any other processing.
                    </p>
                  </li><br/>
                  <li className="text-muted">CHANGES TO PRIVACY POLICY
                    <p className="text-muted mt-3 mb-0">
                      If we change our Privacy Policy, we will post the changes on this page, and may place notices on other pages of the Heme App, so that you may be aware of the information we collect and how we use it at all times. We will also e-mail you should we make any changes, so that you may consent to our use of your information in that way. Continued use of the service will signify that you agree to any such changes. By using this Heme App, you consent to the terms of this Privacy Policy and signify your assent to all of the terms of this Privacy Policy and our Terms of Use. If you do not agree with any term of this Privacy Policy or our Terms of Use, please do not use this site or submit any personally identifiable information.
                    </p>
                  </li>
                </ol><br/>
                <h6>Disclaimer of Warranties</h6>
                <p className="text-muted mt-3 mb-0">The Website and the App are provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.</p>
                <p className="text-muted mt-3 mb-0">The Company does not warrant that the Website or the App will be uninterrupted or error-free, that defects will be corrected, or that the Website or the App are free of viruses or other harmful components. The Company also does not warrant that the results obtained from the use of the Website or the App will be</p><br/>
                <h6>Permissions Required:</h6>
                <p className="text-muted mt-3 mb-0">Storage: The app may require permission to access the user's device storage to store and manage health information such as lab reports, medical images, or other relevant documents. This feature can help users access their health information anytime, anywhere, without the need for physical copies or manual tracking.</p>
                <p className="text-muted mt-3 mb-0">It's important to note that HemeHealth will only use these permissions for the specific purposes mentioned above and will not share or misuse any user data. Users can choose to grant or revoke these permissions at any time through their device settings.</p>
                <ul>
                    <li className="text-muted">HOW CAN YOU CONTACT US? We welcome your views about our Heme App and our privacy policy. Please feel free to contact us at <a href="mailto:info@hemehealth.io" style={{
                      color: "#7ABA56",
                    }}>info@hemehealth.io</a></li>
                    <li className="text-muted">DATA PROTECTION OFFICER AND GRIEVANCE OFFICER Blenheim Chalcot India Private Limited is the controller and processor of data with respect to the Fand App and information shared therein. In case of any queries regarding the processing of Your personal data, any discrepancies or grievances in relation to the collection, storage, use, disclosure and transfer of Your Personal Information under this Policy or any terms of Blenheim Chalcot India Private Limited’s Terms of Use, or other polices, or if You wish to exercise any of Your rights, please contact our Data Protection Officer/ Grievance Officer in accordance with the General Data Protection Regulation or the Information Technology Act 2000 and rules made there under respectively as applicable. In case of grievances you can contact:</li>
                </ul>
                <p className="text-muted mt-3 mb-0">Contact Name: Rohit Sareen</p>
                <p className="text-muted mt-3 mb-0">Email: rohit@hemehealth.io</p>
                <p className="text-muted mt-3 mb-0">Last updated: 27 April 2023</p>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section "
          style={{
            backgroundImage: `url(${footerImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            height: "100%",
          }}
          id="beta"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 text-center">
                <div className="title-heading mt-4">
                  {/* <div className="alert alert-transparent alert-pills shadow" role="alert">
                                  <span className="badge rounded-pill bg-primary me-1">Heme</span>
                                  <span className="content">Your Health Records</span>
                              </div> */}
                  <h1 className="heading title-dark text-white mb-3">
                    We positively impact lives using technology
                  </h1>
                  <p className="para-desc para-dark text-light mx-auto">
                    Download HemeHealth app to securely store, access and analyse
                    your laboratory reports, for FREE!
                  </p>
                  {/* <div className="mt-4 pt-2">
                          <a
                            //onClick={this.submitHandler}
                            href="#"
                            className="btn btn-primary1 m-1"
                          >
                            Join Our Beta Program
                          </a>
                        </div> */}
                  <div className="mt-4">
                    <a href="https://apple.co/3CLxrQu">
                      <img
                        src="asset/images/app/appstore.svg"
                        className="m-1"
                        height="50"
                        alt=""
                      />
                    </a>

                    <a href="https://bit.ly/3xAMh9r">
                      <img
                        src="asset/images/app/playstore.svg"
                        className="m-1"
                        height="50"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer">
          <div className="footer-py-15 footer-bar">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-sm-12">
                  <div className="text-sm">
                    <p className="mb-0">
                      © Copyright 2022, HemeHealth |{" "}
                      <Link className="text-foot" to="/Privacy">
                        Privacy Policy
                      </Link>{" "}
                      |{" "}
                      <Link className="text-foot" to="/Privacy">
                        Terms and Condition
                      </Link>
                      |{" "}
                      <a href="javascript:void(0)">
                        <img src="asset/images/app/facebook.svg" height="15" />
                      </a>
                      &nbsp; &nbsp;
                      <a href="javascript:void(0)">
                        <img src="asset/images/app/twitter.svg" height="15" />
                      </a>
                      &nbsp; &nbsp;
                      <a href="javascript:void(0)">
                        <img src="asset/images/app/linkedIn.svg" height="15" />
                      </a>
                    </p>

                    {/* <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                            <li className="list-inline-item">
                              <a href="javascript:void(0)" className="rounded">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-facebook fea icon-sm fea-social"
                                >
                                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                </svg>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="javascript:void(0)" className="rounded">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-instagram fea icon-sm fea-social"
                                >
                                  <rect
                                    x="2"
                                    y="2"
                                    width="20"
                                    height="20"
                                    rx="5"
                                    ry="5"
                                  ></rect>
                                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                  <line
                                    x1="17.5"
                                    y1="6.5"
                                    x2="17.51"
                                    y2="6.5"
                                  ></line>
                                </svg>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="javascript:void(0)" className="rounded">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-twitter fea icon-sm fea-social"
                                >
                                  <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                                </svg>
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="javascript:void(0)" className="rounded">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-linkedin fea icon-sm fea-social"
                                >
                                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                  <rect x="2" y="9" width="4" height="12"></rect>
                                  <circle cx="4" cy="4" r="2"></circle>
                                </svg>
                              </a>
                            </li>
                          </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div
          className="offcanvas offcanvas-end bg-white shadow"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header p-4 border-bottom">
            <h5 id="offcanvasRightLabel" className="mb-0">
              <img src="" height="24" className="light-version" alt="" />
              <img src="" height="24" className="dark-version" alt="" />
            </h5>
            <button
              type="button"
              className="btn-close d-flex align-items-center text-dark"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i className="uil uil-times fs-4"></i>
            </button>
          </div>
          <div className="offcanvas-body p-4">
            <div className="row">
              <div className="col-12">
                <img
                  src="asset/images/contact.svg"
                  className="img-fluid d-block mx-auto"
                  style={{ maxWidth: "256px" }}
                  alt=""
                />
                <div className="card border-0 mt-5" style={{ zIndex: "1" }}>
                  <div className="card-body p-0">
                    <form
                      method="post"
                      name="myForm"
                      onsubmit="return validateForm()"
                    >
                      <p id="error-msg" className="mb-0"></p>
                      <div id="simple-msg"></div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Your Name <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <i
                                data-feather="user"
                                className="fea icon-sm icons"
                              ></i>
                              <input
                                name="name"
                                id="name"
                                type="text"
                                className="form-control ps-5"
                                placeholder="Name :"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Your Email <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <i
                                data-feather="mail"
                                className="fea icon-sm icons"
                              ></i>
                              <input
                                name="email"
                                id="email"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Email :"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">Subject</label>
                            <div className="form-icon position-relative">
                              <i
                                data-feather="book"
                                className="fea icon-sm icons"
                              ></i>
                              <input
                                name="subject"
                                id="subject"
                                className="form-control ps-5"
                                placeholder="subject :"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Comments <span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <i
                                data-feather="message-circle"
                                className="fea icon-sm icons clearfix"
                              ></i>
                              <textarea
                                name="comments"
                                id="comments"
                                rows="4"
                                className="form-control ps-5"
                                placeholder="Message :"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-grid">
                            <button
                              type="submit"
                              id="submit"
                              name="send"
                              className="btn btn-primary"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="offcanvas-footer p-4 border-top text-center">
            <ul className="list-unstyled social-icon social mb-0">
              <li className="list-inline-item mb-0">
                <a
                  href="https://dribbble.com/"
                  target="_blank"
                  className="rounded"
                >
                  <i
                    className="uil uil-dribbble align-middle"
                    title="dribbble"
                  ></i>
                </a>
              </li>
              <li className="list-inline-item mb-0">
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  className="rounded"
                >
                  <i
                    className="uil uil-facebook-f align-middle"
                    title="facebook"
                  ></i>
                </a>
              </li>
              <li className="list-inline-item mb-0">
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  className="rounded"
                >
                  <i
                    className="uil uil-instagram align-middle"
                    title="instagram"
                  ></i>
                </a>
              </li>
              <li className="list-inline-item mb-0">
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  className="rounded"
                >
                  <i className="uil uil-twitter align-middle" title="twitter"></i>
                </a>
              </li>
              <li className="list-inline-item mb-0">
                <a href="" className="rounded">
                  <i className="uil uil-envelope align-middle" title="email"></i>
                </a>
              </li>
              <li className="list-inline-item mb-0">
                <a href="" target="_blank" className="rounded">
                  <i className="uil uil-globe align-middle" title="website"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="cookie-popup bg-white shadow rounded py-3 px-4">
              <p className="text-muted mb-0">This website uses cookies to provide you with a great user experience. By using it, you accept our <a href="" target="_blank" className="text-success h6">use of cookies</a></p>
              <div className="cookie-popup-actions text-end">
                  <button><i className="uil uil-times text-dark fs-4"></i></button>
              </div>
          </div> */}
        <a
          href="#"
          onclick="topFunction()"
          id="back-to-top"
          className="back-to-top fs-5"
          style={{bottom: 60}}
        >
          <i
            data-feather="arrow-up"
            className="fea icon-sm icons align-middle"
          ></i>
        </a>
      </div>
    );
  }
