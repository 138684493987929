import React from "react";
import { Link } from 'react-router-dom';
import bannerImage from "../images/top-header-with-patient.png";
import footerImage from "../images/bottom-background.png";
import Select from "react-select"
 
// const SPREADSHEET_ID='1lBSwR0pDsdD_wRhnNTa32kdSbZXkLY0m0dHf91aMv7E';
// const CLIENT_ID='389632889048-0r6l759cehsjheb3tkrubjh59o92bmb8.apps.googleusercontent.com';
// const API_KEY='AIzaSyDjUnxTQ68BoCdYvfNYULdzO1jPuRpoos4';
// const SCOPE = 'https://www.googleapis.com/auth/spreadsheets';



{/* <Helmet>
    <script src=
"https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js" 
    type="text/javascript" />
     <script
  src="https://code.jquery.com/jquery-3.4.1.js"
  integrity="sha256-WpOohJOqMqqyKL9FccASB9O0KwACQJpFTUBLTYOVvVU="
  crossorigin="anonymous" />
    </Helmet> */}
    const options = [
      {value: ' Adilabad ' ,label:' Adilabad ' },
      {value: ' Agra ' ,label:' Agra ' },
      {value: ' Ahmedabad ' ,label:' Ahmedabad ' },
      {value: ' Ahmednagar ' ,label:' Ahmednagar ' },
      {value: ' Aizawl ' ,label:' Aizawl ' },
      {value: ' Ajitgarh (Mohali) ' ,label:' Ajitgarh (Mohali) ' },
      {value: ' Ajmer ' ,label:' Ajmer ' },
      {value: ' Akola ' ,label:' Akola ' },
      {value: ' Alappuzha ' ,label:' Alappuzha ' },
      {value: ' Aligarh ' ,label:' Aligarh ' },
      {value: ' Alirajpur ' ,label:' Alirajpur ' },
      {value: ' Allahabad ' ,label:' Allahabad ' },
      {value: ' Almora ' ,label:' Almora ' },
      {value: ' Alwar ' ,label:' Alwar ' },
      {value: ' Ambala ' ,label:' Ambala ' },
      {value: ' Ambedkar Nagar ' ,label:' Ambedkar Nagar ' },
      {value: ' Amravati ' ,label:' Amravati ' },
      {value: ' Amreli district ' ,label:' Amreli district ' },
      {value: ' Amritsar ' ,label:' Amritsar ' },
      {value: ' Anand ' ,label:' Anand ' },
      {value: ' Anantapur ' ,label:' Anantapur ' },
      {value: ' Anantnag ' ,label:' Anantnag ' },
      {value: ' Angul ' ,label:' Angul ' },
      {value: ' Anjaw ' ,label:' Anjaw ' },
      {value: ' Anuppur ' ,label:' Anuppur ' },
      {value: ' Araria ' ,label:' Araria ' },
      {value: ' Ariyalur ' ,label:' Ariyalur ' },
      {value: ' Arwal ' ,label:' Arwal ' },
      {value: ' Ashok Nagar ' ,label:' Ashok Nagar ' },
      {value: ' Auraiya ' ,label:' Auraiya ' },
      {value: ' Aurangabad ' ,label:' Aurangabad ' },
      {value: ' Aurangabad ' ,label:' Aurangabad ' },
      {value: ' Azamgarh ' ,label:' Azamgarh ' },
      {value: ' Badgam ' ,label:' Badgam ' },
      {value: ' Bagalkot ' ,label:' Bagalkot ' },
      {value: ' Bageshwar ' ,label:' Bageshwar ' },
      {value: ' Bagpat ' ,label:' Bagpat ' },
      {value: ' Bahraich ' ,label:' Bahraich ' },
      {value: ' Baksa ' ,label:' Baksa ' },
      {value: ' Balaghat ' ,label:' Balaghat ' },
      {value: ' Balangir ' ,label:' Balangir ' },
      {value: ' Balasore ' ,label:' Balasore ' },
      {value: ' Ballia ' ,label:' Ballia ' },
      {value: ' Balrampur ' ,label:' Balrampur ' },
      {value: ' Banaskantha ' ,label:' Banaskantha ' },
      {value: ' Banda ' ,label:' Banda ' },
      {value: ' Bandipora ' ,label:' Bandipora ' },
      {value: ' Bangalore Rural ' ,label:' Bangalore Rural ' },
      {value: ' Bangalore Urban ' ,label:' Bangalore Urban ' },
      {value: ' Banka ' ,label:' Banka ' },
      {value: ' Bankura ' ,label:' Bankura ' },
      {value: ' Banswara ' ,label:' Banswara ' },
      {value: ' Barabanki ' ,label:' Barabanki ' },
      {value: ' Baramulla ' ,label:' Baramulla ' },
      {value: ' Baran ' ,label:' Baran ' },
      {value: ' Bardhaman ' ,label:' Bardhaman ' },
      {value: ' Bareilly ' ,label:' Bareilly ' },
      {value: ' Bargarh (Baragarh) ' ,label:' Bargarh (Baragarh) ' },
      {value: ' Barmer ' ,label:' Barmer ' },
      {value: ' Barnala ' ,label:' Barnala ' },
      {value: ' Barpeta ' ,label:' Barpeta ' },
      {value: ' Barwani ' ,label:' Barwani ' },
      {value: ' Bastar ' ,label:' Bastar ' },
      {value: ' Basti ' ,label:' Basti ' },
      {value: ' Bathinda ' ,label:' Bathinda ' },
      {value: ' Beed ' ,label:' Beed ' },
      {value: ' Begusarai ' ,label:' Begusarai ' },
      {value: ' Belgaum ' ,label:' Belgaum ' },
      {value: ' Bellary ' ,label:' Bellary ' },
      {value: ' Betul ' ,label:' Betul ' },
      {value: ' Bhadrak ' ,label:' Bhadrak ' },
      {value: ' Bhagalpur ' ,label:' Bhagalpur ' },
      {value: ' Bhandara ' ,label:' Bhandara ' },
      {value: ' Bharatpur ' ,label:' Bharatpur ' },
      {value: ' Bharuch ' ,label:' Bharuch ' },
      {value: ' Bhavnagar ' ,label:' Bhavnagar ' },
      {value: ' Bhilwara ' ,label:' Bhilwara ' },
      {value: ' Bhind ' ,label:' Bhind ' },
      {value: ' Bhiwani ' ,label:' Bhiwani ' },
      {value: ' Bhojpur ' ,label:' Bhojpur ' },
      {value: ' Bhopal ' ,label:' Bhopal ' },
      {value: ' Bidar ' ,label:' Bidar ' },
      {value: ' Bijapur ' ,label:' Bijapur ' },
      {value: ' Bijapur ' ,label:' Bijapur ' },
      {value: ' Bijnor ' ,label:' Bijnor ' },
      {value: ' Bikaner ' ,label:' Bikaner ' },
      {value: ' Bilaspur ' ,label:' Bilaspur ' },
      {value: ' Bilaspur ' ,label:' Bilaspur ' },
      {value: ' Birbhum ' ,label:' Birbhum ' },
      {value: ' Bishnupur ' ,label:' Bishnupur ' },
      {value: ' Bokaro ' ,label:' Bokaro ' },
      {value: ' Bongaigaon ' ,label:' Bongaigaon ' },
      {value: ' Boudh (Bauda) ' ,label:' Boudh (Bauda) ' },
      {value: ' Budaun ' ,label:' Budaun ' },
      {value: ' Bulandshahr ' ,label:' Bulandshahr ' },
      {value: ' Buldhana ' ,label:' Buldhana ' },
      {value: ' Bundi ' ,label:' Bundi ' },
      {value: ' Burhanpur ' ,label:' Burhanpur ' },
      {value: ' Buxar ' ,label:' Buxar ' },
      {value: ' Cachar ' ,label:' Cachar ' },
      {value: ' Central Delhi ' ,label:' Central Delhi ' },
      {value: ' Chamarajnagar ' ,label:' Chamarajnagar ' },
      {value: ' Chamba ' ,label:' Chamba ' },
      {value: ' Chamoli ' ,label:' Chamoli ' },
      {value: ' Champawat ' ,label:' Champawat ' },
      {value: ' Champhai ' ,label:' Champhai ' },
      {value: ' Chandauli ' ,label:' Chandauli ' },
      {value: ' Chandel ' ,label:' Chandel ' },
      {value: ' Chandigarh ' ,label:' Chandigarh ' },
      {value: ' Chandrapur ' ,label:' Chandrapur ' },
      {value: ' Changlang ' ,label:' Changlang ' },
      {value: ' Chatra ' ,label:' Chatra ' },
      {value: ' Chennai ' ,label:' Chennai ' },
      {value: ' Chhatarpur ' ,label:' Chhatarpur ' },
      {value: ' Chhatrapati Shahuji Maharaj Nagar ' ,label:' Chhatrapati Shahuji Maharaj Nagar ' },
      {value: ' Chhindwara ' ,label:' Chhindwara ' },
      {value: ' Chikkaballapur ' ,label:' Chikkaballapur ' },
      {value: ' Chikkamagaluru ' ,label:' Chikkamagaluru ' },
      {value: ' Chirang ' ,label:' Chirang ' },
      {value: ' Chitradurga ' ,label:' Chitradurga ' },
      {value: ' Chitrakoot ' ,label:' Chitrakoot ' },
      {value: ' Chittoor ' ,label:' Chittoor ' },
      {value: ' Chittorgarh ' ,label:' Chittorgarh ' },
      {value: ' Churachandpur ' ,label:' Churachandpur ' },
      {value: ' Churu ' ,label:' Churu ' },
      {value: ' Coimbatore ' ,label:' Coimbatore ' },
      {value: ' Cooch Behar ' ,label:' Cooch Behar ' },
      {value: ' Cuddalore ' ,label:' Cuddalore ' },
      {value: ' Cuttack ' ,label:' Cuttack ' },
      {value: ' Dadra and Nagar Haveli ' ,label:' Dadra and Nagar Haveli ' },
      {value: ' Dahod ' ,label:' Dahod ' },
      {value: ' Dakshin Dinajpur ' ,label:' Dakshin Dinajpur ' },
      {value: ' Dakshina Kannada ' ,label:' Dakshina Kannada ' },
      {value: ' Daman ' ,label:' Daman ' },
      {value: ' Damoh ' ,label:' Damoh ' },
      {value: ' Dantewada ' ,label:' Dantewada ' },
      {value: ' Darbhanga ' ,label:' Darbhanga ' },
      {value: ' Darjeeling ' ,label:' Darjeeling ' },
      {value: ' Darrang ' ,label:' Darrang ' },
      {value: ' Datia ' ,label:' Datia ' },
      {value: ' Dausa ' ,label:' Dausa ' },
      {value: ' Davanagere ' ,label:' Davanagere ' },
      {value: ' Debagarh (Deogarh) ' ,label:' Debagarh (Deogarh) ' },
      {value: ' Dehradun ' ,label:' Dehradun ' },
      {value: ' Deoghar ' ,label:' Deoghar ' },
      {value: ' Deoria ' ,label:' Deoria ' },
      {value: ' Dewas ' ,label:' Dewas ' },
      {value: ' Dhalai ' ,label:' Dhalai ' },
      {value: ' Dhamtari ' ,label:' Dhamtari ' },
      {value: ' Dhanbad ' ,label:' Dhanbad ' },
      {value: ' Dhar ' ,label:' Dhar ' },
      {value: ' Dharmapuri ' ,label:' Dharmapuri ' },
      {value: ' Dharwad ' ,label:' Dharwad ' },
      {value: ' Dhemaji ' ,label:' Dhemaji ' },
      {value: ' Dhenkanal ' ,label:' Dhenkanal ' },
      {value: ' Dholpur ' ,label:' Dholpur ' },
      {value: ' Dhubri ' ,label:' Dhubri ' },
      {value: ' Dhule ' ,label:' Dhule ' },
      {value: ' Dibang Valley ' ,label:' Dibang Valley ' },
      {value: ' Dibrugarh ' ,label:' Dibrugarh ' },
      {value: ' Dima Hasao ' ,label:' Dima Hasao ' },
      {value: ' Dimapur ' ,label:' Dimapur ' },
      {value: ' Dindigul ' ,label:' Dindigul ' },
      {value: ' Dindori ' ,label:' Dindori ' },
      {value: ' Diu ' ,label:' Diu ' },
      {value: ' Doda ' ,label:' Doda ' },
      {value: ' Dumka ' ,label:' Dumka ' },
      {value: ' Dungapur ' ,label:' Dungapur ' },
      {value: ' Durg ' ,label:' Durg ' },
      {value: ' East Champaran ' ,label:' East Champaran ' },
      {value: ' East Delhi ' ,label:' East Delhi ' },
      {value: ' East Garo Hills ' ,label:' East Garo Hills ' },
      {value: ' East Khasi Hills ' ,label:' East Khasi Hills ' },
      {value: ' East Siang ' ,label:' East Siang ' },
      {value: ' East Sikkim ' ,label:' East Sikkim ' },
      {value: ' East Singhbhum ' ,label:' East Singhbhum ' },
      {value: ' Eluru ' ,label:' Eluru ' },
      {value: ' Ernakulam ' ,label:' Ernakulam ' },
      {value: ' Erode ' ,label:' Erode ' },
      {value: ' Etah ' ,label:' Etah ' },
      {value: ' Etawah ' ,label:' Etawah ' },
      {value: ' Faizabad ' ,label:' Faizabad ' },
      {value: ' Faridabad ' ,label:' Faridabad ' },
      {value: ' Faridkot ' ,label:' Faridkot ' },
      {value: ' Farrukhabad ' ,label:' Farrukhabad ' },
      {value: ' Fatehabad ' ,label:' Fatehabad ' },
      {value: ' Fatehgarh Sahib ' ,label:' Fatehgarh Sahib ' },
      {value: ' Fatehpur ' ,label:' Fatehpur ' },
      {value: ' Fazilka ' ,label:' Fazilka ' },
      {value: ' Firozabad ' ,label:' Firozabad ' },
      {value: ' Firozpur ' ,label:' Firozpur ' },
      {value: ' Gadag ' ,label:' Gadag ' },
      {value: ' Gadchiroli ' ,label:' Gadchiroli ' },
      {value: ' Gajapati ' ,label:' Gajapati ' },
      {value: ' Ganderbal ' ,label:' Ganderbal ' },
      {value: ' Gandhinagar ' ,label:' Gandhinagar ' },
      {value: ' Ganganagar ' ,label:' Ganganagar ' },
      {value: ' Ganjam ' ,label:' Ganjam ' },
      {value: ' Garhwa ' ,label:' Garhwa ' },
      {value: ' Gautam Buddh Nagar ' ,label:' Gautam Buddh Nagar ' },
      {value: ' Gaya ' ,label:' Gaya ' },
      {value: ' Ghaziabad ' ,label:' Ghaziabad ' },
      {value: ' Ghazipur ' ,label:' Ghazipur ' },
      {value: ' Giridih ' ,label:' Giridih ' },
      {value: ' Goalpara ' ,label:' Goalpara ' },
      {value: ' Godda ' ,label:' Godda ' },
      {value: ' Golaghat ' ,label:' Golaghat ' },
      {value: ' Gonda ' ,label:' Gonda ' },
      {value: ' Gondia ' ,label:' Gondia ' },
      {value: ' Gopalganj ' ,label:' Gopalganj ' },
      {value: ' Gorakhpur ' ,label:' Gorakhpur ' },
      {value: ' Gulbarga ' ,label:' Gulbarga ' },
      {value: ' Gumla ' ,label:' Gumla ' },
      {value: ' Guna ' ,label:' Guna ' },
      {value: ' Guntur ' ,label:' Guntur ' },
      {value: ' Gurdaspur ' ,label:' Gurdaspur ' },
      {value: ' Gurgaon ' ,label:' Gurgaon ' },
      {value: ' Gwalior ' ,label:' Gwalior ' },
      {value: ' Hailakandi ' ,label:' Hailakandi ' },
      {value: ' Hamirpur ' ,label:' Hamirpur ' },
      {value: ' Hamirpur ' ,label:' Hamirpur ' },
      {value: ' Hanumangarh ' ,label:' Hanumangarh ' },
      {value: ' Harda ' ,label:' Harda ' },
      {value: ' Hardoi ' ,label:' Hardoi ' },
      {value: ' Haridwar ' ,label:' Haridwar ' },
      {value: ' Hassan ' ,label:' Hassan ' },
      {value: ' Haveri district ' ,label:' Haveri district ' },
      {value: ' Hazaribag ' ,label:' Hazaribag ' },
      {value: ' Hingoli ' ,label:' Hingoli ' },
      {value: ' Hissar ' ,label:' Hissar ' },
      {value: ' Hooghly ' ,label:' Hooghly ' },
      {value: ' Hoshangabad ' ,label:' Hoshangabad ' },
      {value: ' Hoshiarpur ' ,label:' Hoshiarpur ' },
      {value: ' Howrah ' ,label:' Howrah ' },
      {value: ' Hyderabad ' ,label:' Hyderabad ' },
      {value: ' Hyderabad ' ,label:' Hyderabad ' },
      {value: ' Idukki ' ,label:' Idukki ' },
      {value: ' Imphal East ' ,label:' Imphal East ' },
      {value: ' Imphal West ' ,label:' Imphal West ' },
      {value: ' Indore ' ,label:' Indore ' },
      {value: ' Jabalpur ' ,label:' Jabalpur ' },
      {value: ' Jagatsinghpur ' ,label:' Jagatsinghpur ' },
      {value: ' Jaintia Hills ' ,label:' Jaintia Hills ' },
      {value: ' Jaipur ' ,label:' Jaipur ' },
      {value: ' Jaisalmer ' ,label:' Jaisalmer ' },
      {value: ' Jajpur ' ,label:' Jajpur ' },
      {value: ' Jalandhar ' ,label:' Jalandhar ' },
      {value: ' Jalaun ' ,label:' Jalaun ' },
      {value: ' Jalgaon ' ,label:' Jalgaon ' },
      {value: ' Jalna ' ,label:' Jalna ' },
      {value: ' Jalore ' ,label:' Jalore ' },
      {value: ' Jalpaiguri ' ,label:' Jalpaiguri ' },
      {value: ' Jammu ' ,label:' Jammu ' },
      {value: ' Jamnagar ' ,label:' Jamnagar ' },
      {value: ' Jamtara ' ,label:' Jamtara ' },
      {value: ' Jamui ' ,label:' Jamui ' },
      {value: ' Janjgir-Champa ' ,label:' Janjgir-Champa ' },
      {value: ' Jashpur ' ,label:' Jashpur ' },
      {value: ' Jaunpur district ' ,label:' Jaunpur district ' },
      {value: ' Jehanabad ' ,label:' Jehanabad ' },
      {value: ' Jhabua ' ,label:' Jhabua ' },
      {value: ' Jhajjar ' ,label:' Jhajjar ' },
      {value: ' Jhalawar ' ,label:' Jhalawar ' },
      {value: ' Jhansi ' ,label:' Jhansi ' },
      {value: ' Jharsuguda ' ,label:' Jharsuguda ' },
      {value: ' Jhunjhunu ' ,label:' Jhunjhunu ' },
      {value: ' Jind ' ,label:' Jind ' },
      {value: ' Jodhpur ' ,label:' Jodhpur ' },
      {value: ' Jorhat ' ,label:' Jorhat ' },
      {value: ' Junagadh ' ,label:' Junagadh ' },
      {value: ' Jyotiba Phule Nagar ' ,label:' Jyotiba Phule Nagar ' },
      {value: ' Kabirdham (formerly Kawardha) ' ,label:' Kabirdham (formerly Kawardha) ' },
      {value: ' Kadapa ' ,label:' Kadapa ' },
      {value: ' Kaimur ' ,label:' Kaimur ' },
      {value: ' Kaithal ' ,label:' Kaithal ' },
      {value: ' Kakinada ' ,label:' Kakinada ' },
      {value: ' Kalahandi ' ,label:' Kalahandi ' },
      {value: ' Kamrup ' ,label:' Kamrup ' },
      {value: ' Kamrup Metropolitan ' ,label:' Kamrup Metropolitan ' },
      {value: ' Kanchipuram ' ,label:' Kanchipuram ' },
      {value: ' Kandhamal ' ,label:' Kandhamal ' },
      {value: ' Kangra ' ,label:' Kangra ' },
      {value: ' Kanker ' ,label:' Kanker ' },
      {value: ' Kannauj ' ,label:' Kannauj ' },
      {value: ' Kannur ' ,label:' Kannur ' },
      {value: ' Kanpur ' ,label:' Kanpur ' },
      {value: ' Kanshi Ram Nagar ' ,label:' Kanshi Ram Nagar ' },
      {value: ' Kanyakumari ' ,label:' Kanyakumari ' },
      {value: ' Kapurthala ' ,label:' Kapurthala ' },
      {value: ' Karaikal ' ,label:' Karaikal ' },
      {value: ' Karauli ' ,label:' Karauli ' },
      {value: ' Karbi Anglong ' ,label:' Karbi Anglong ' },
      {value: ' Kargil ' ,label:' Kargil ' },
      {value: ' Karimganj ' ,label:' Karimganj ' },
      {value: ' Karimnagar ' ,label:' Karimnagar ' },
      {value: ' Karnal ' ,label:' Karnal ' },
      {value: ' Karur ' ,label:' Karur ' },
      {value: ' Kasaragod ' ,label:' Kasaragod ' },
      {value: ' Kathua ' ,label:' Kathua ' },
      {value: ' Katihar ' ,label:' Katihar ' },
      {value: ' Katni ' ,label:' Katni ' },
      {value: ' Kaushambi ' ,label:' Kaushambi ' },
      {value: ' Kendrapara ' ,label:' Kendrapara ' },
      {value: ' Kendujhar (Keonjhar) ' ,label:' Kendujhar (Keonjhar) ' },
      {value: ' Khagaria ' ,label:' Khagaria ' },
      {value: ' Khammam ' ,label:' Khammam ' },
      {value: ' Khandwa (East Nimar) ' ,label:' Khandwa (East Nimar) ' },
      {value: ' Khargone (West Nimar) ' ,label:' Khargone (West Nimar) ' },
      {value: ' Kheda ' ,label:' Kheda ' },
      {value: ' Khordha ' ,label:' Khordha ' },
      {value: ' Khowai ' ,label:' Khowai ' },
      {value: ' Khunti ' ,label:' Khunti ' },
      {value: ' Kinnaur ' ,label:' Kinnaur ' },
      {value: ' Kishanganj ' ,label:' Kishanganj ' },
      {value: ' Kishtwar ' ,label:' Kishtwar ' },
      {value: ' Kodagu ' ,label:' Kodagu ' },
      {value: ' Koderma ' ,label:' Koderma ' },
      {value: ' Kohima ' ,label:' Kohima ' },
      {value: ' Kokrajhar ' ,label:' Kokrajhar ' },
      {value: ' Kolar ' ,label:' Kolar ' },
      {value: ' Kolasib ' ,label:' Kolasib ' },
      {value: ' Kolhapur ' ,label:' Kolhapur ' },
      {value: ' Kolkata ' ,label:' Kolkata ' },
      {value: ' Kollam ' ,label:' Kollam ' },
      {value: ' Koppal ' ,label:' Koppal ' },
      {value: ' Koraput ' ,label:' Koraput ' },
      {value: ' Korba ' ,label:' Korba ' },
      {value: ' Koriya ' ,label:' Koriya ' },
      {value: ' Kota ' ,label:' Kota ' },
      {value: ' Kottayam ' ,label:' Kottayam ' },
      {value: ' Kozhikode ' ,label:' Kozhikode ' },
      {value: ' Krishna ' ,label:' Krishna ' },
      {value: ' Kulgam ' ,label:' Kulgam ' },
      {value: ' Kullu ' ,label:' Kullu ' },
      {value: ' Kupwara ' ,label:' Kupwara ' },
      {value: ' Kurnool ' ,label:' Kurnool ' },
      {value: ' Kurukshetra ' ,label:' Kurukshetra ' },
      {value: ' Kurung Kumey ' ,label:' Kurung Kumey ' },
      {value: ' Kushinagar ' ,label:' Kushinagar ' },
      {value: ' Kutch ' ,label:' Kutch ' },
      {value: ' Lahaul and Spiti ' ,label:' Lahaul and Spiti ' },
      {value: ' Lakhimpur ' ,label:' Lakhimpur ' },
      {value: ' Lakhimpur Kheri ' ,label:' Lakhimpur Kheri ' },
      {value: ' Lakhisarai ' ,label:' Lakhisarai ' },
      {value: ' Lalitpur ' ,label:' Lalitpur ' },
      {value: ' Latehar ' ,label:' Latehar ' },
      {value: ' Latur ' ,label:' Latur ' },
      {value: ' Lawngtlai ' ,label:' Lawngtlai ' },
      {value: ' Leh ' ,label:' Leh ' },
      {value: ' Lohardaga ' ,label:' Lohardaga ' },
      {value: ' Lohit ' ,label:' Lohit ' },
      {value: ' Lower Dibang Valley ' ,label:' Lower Dibang Valley ' },
      {value: ' Lower Subansiri ' ,label:' Lower Subansiri ' },
      {value: ' Lucknow ' ,label:' Lucknow ' },
      {value: ' Ludhiana ' ,label:' Ludhiana ' },
      {value: ' Lunglei ' ,label:' Lunglei ' },
      {value: ' Madhepura ' ,label:' Madhepura ' },
      {value: ' Madhubani ' ,label:' Madhubani ' },
      {value: ' Madurai ' ,label:' Madurai ' },
      {value: ' Mahamaya Nagar ' ,label:' Mahamaya Nagar ' },
      {value: ' Maharajganj ' ,label:' Maharajganj ' },
      {value: ' Mahasamund ' ,label:' Mahasamund ' },
      {value: ' Mahbubnagar ' ,label:' Mahbubnagar ' },
      {value: ' Mahe ' ,label:' Mahe ' },
      {value: ' Mahendragarh ' ,label:' Mahendragarh ' },
      {value: ' Mahoba ' ,label:' Mahoba ' },
      {value: ' Mainpuri ' ,label:' Mainpuri ' },
      {value: ' Malappuram ' ,label:' Malappuram ' },
      {value: ' Maldah ' ,label:' Maldah ' },
      {value: ' Malkangiri ' ,label:' Malkangiri ' },
      {value: ' Mamit ' ,label:' Mamit ' },
      {value: ' Mandi ' ,label:' Mandi ' },
      {value: ' Mandla ' ,label:' Mandla ' },
      {value: ' Mandsaur ' ,label:' Mandsaur ' },
      {value: ' Mandya ' ,label:' Mandya ' },
      {value: ' Mansa ' ,label:' Mansa ' },
      {value: ' Marigaon ' ,label:' Marigaon ' },
      {value: ' Mathura ' ,label:' Mathura ' },
      {value: ' Mau ' ,label:' Mau ' },
      {value: ' Mayurbhanj ' ,label:' Mayurbhanj ' },
      {value: ' Medak ' ,label:' Medak ' },
      {value: ' Meerut ' ,label:' Meerut ' },
      {value: ' Mehsana ' ,label:' Mehsana ' },
      {value: ' Mewat ' ,label:' Mewat ' },
      {value: ' Mirzapur ' ,label:' Mirzapur ' },
      {value: ' Moga ' ,label:' Moga ' },
      {value: ' Mokokchung ' ,label:' Mokokchung ' },
      {value: ' Mon ' ,label:' Mon ' },
      {value: ' Moradabad ' ,label:' Moradabad ' },
      {value: ' Morena ' ,label:' Morena ' },
      {value: ' Mumbai City ' ,label:' Mumbai City ' },
      {value: ' Mumbai suburban ' ,label:' Mumbai suburban ' },
      {value: ' Munger ' ,label:' Munger ' },
      {value: ' Murshidabad ' ,label:' Murshidabad ' },
      {value: ' Muzaffarnagar ' ,label:' Muzaffarnagar ' },
      {value: ' Muzaffarpur ' ,label:' Muzaffarpur ' },
      {value: ' Mysore ' ,label:' Mysore ' },
      {value: ' Nabarangpur ' ,label:' Nabarangpur ' },
      {value: ' Nadia ' ,label:' Nadia ' },
      {value: ' Nagaon ' ,label:' Nagaon ' },
      {value: ' Nagapattinam ' ,label:' Nagapattinam ' },
      {value: ' Nagaur ' ,label:' Nagaur ' },
      {value: ' Nagpur ' ,label:' Nagpur ' },
      {value: ' Nainital ' ,label:' Nainital ' },
      {value: ' Nalanda ' ,label:' Nalanda ' },
      {value: ' Nalbari ' ,label:' Nalbari ' },
      {value: ' Nalgonda ' ,label:' Nalgonda ' },
      {value: ' Namakkal ' ,label:' Namakkal ' },
      {value: ' Nanded ' ,label:' Nanded ' },
      {value: ' Nandurbar ' ,label:' Nandurbar ' },
      {value: ' Narayanpur ' ,label:' Narayanpur ' },
      {value: ' Narmada ' ,label:' Narmada ' },
      {value: ' Narsinghpur ' ,label:' Narsinghpur ' },
      {value: ' Nashik ' ,label:' Nashik ' },
      {value: ' Navsari ' ,label:' Navsari ' },
      {value: ' Nawada ' ,label:' Nawada ' },
      {value: ' Nawanshahr ' ,label:' Nawanshahr ' },
      {value: ' Nayagarh ' ,label:' Nayagarh ' },
      {value: ' Neemuch ' ,label:' Neemuch ' },
      {value: ' Nellore ' ,label:' Nellore ' },
      {value: ' New Delhi ' ,label:' New Delhi ' },
      {value: ' Nilgiris ' ,label:' Nilgiris ' },
      {value: ' Nizamabad ' ,label:' Nizamabad ' },
      {value: ' North 24 Parganas ' ,label:' North 24 Parganas ' },
      {value: ' North Delhi ' ,label:' North Delhi ' },
      {value: ' North East Delhi ' ,label:' North East Delhi ' },
      {value: ' North Goa ' ,label:' North Goa ' },
      {value: ' North Sikkim ' ,label:' North Sikkim ' },
      {value: ' North Tripura ' ,label:' North Tripura ' },
      {value: ' North West Delhi ' ,label:' North West Delhi ' },
      {value: ' Nuapada ' ,label:' Nuapada ' },
      {value: ' Ongole ' ,label:' Ongole ' },
      {value: ' Osmanabad ' ,label:' Osmanabad ' },
      {value: ' Pakur ' ,label:' Pakur ' },
      {value: ' Palakkad ' ,label:' Palakkad ' },
      {value: ' Palamu ' ,label:' Palamu ' },
      {value: ' Pali ' ,label:' Pali ' },
      {value: ' Palwal ' ,label:' Palwal ' },
      {value: ' Panchkula ' ,label:' Panchkula ' },
      {value: ' Panchmahal ' ,label:' Panchmahal ' },
      {value: ' Panchsheel Nagar district (Hapur) ' ,label:' Panchsheel Nagar district (Hapur) ' },
      {value: ' Panipat ' ,label:' Panipat ' },
      {value: ' Panna ' ,label:' Panna ' },
      {value: ' Papum Pare ' ,label:' Papum Pare ' },
      {value: ' Parbhani ' ,label:' Parbhani ' },
      {value: ' Paschim Medinipur ' ,label:' Paschim Medinipur ' },
      {value: ' Patan ' ,label:' Patan ' },
      {value: ' Pathanamthitta ' ,label:' Pathanamthitta ' },
      {value: ' Pathankot ' ,label:' Pathankot ' },
      {value: ' Patiala ' ,label:' Patiala ' },
      {value: ' Patna ' ,label:' Patna ' },
      {value: ' Pauri Garhwal ' ,label:' Pauri Garhwal ' },
      {value: ' Perambalur ' ,label:' Perambalur ' },
      {value: ' Phek ' ,label:' Phek ' },
      {value: ' Pilibhit ' ,label:' Pilibhit ' },
      {value: ' Pithoragarh ' ,label:' Pithoragarh ' },
      {value: ' Pondicherry ' ,label:' Pondicherry ' },
      {value: ' Poonch ' ,label:' Poonch ' },
      {value: ' Porbandar ' ,label:' Porbandar ' },
      {value: ' Pratapgarh ' ,label:' Pratapgarh ' },
      {value: ' Pratapgarh ' ,label:' Pratapgarh ' },
      {value: ' Pudukkottai ' ,label:' Pudukkottai ' },
      {value: ' Pulwama ' ,label:' Pulwama ' },
      {value: ' Pune ' ,label:' Pune ' },
      {value: ' Purba Medinipur ' ,label:' Purba Medinipur ' },
      {value: ' Puri ' ,label:' Puri ' },
      {value: ' Purnia ' ,label:' Purnia ' },
      {value: ' Purulia ' ,label:' Purulia ' },
      {value: ' Raebareli ' ,label:' Raebareli ' },
      {value: ' Raichur ' ,label:' Raichur ' },
      {value: ' Raigad ' ,label:' Raigad ' },
      {value: ' Raigarh ' ,label:' Raigarh ' },
      {value: ' Raipur ' ,label:' Raipur ' },
      {value: ' Raisen ' ,label:' Raisen ' },
      {value: ' Rajauri ' ,label:' Rajauri ' },
      {value: ' Rajgarh ' ,label:' Rajgarh ' },
      {value: ' Rajkot ' ,label:' Rajkot ' },
      {value: ' Rajnandgaon ' ,label:' Rajnandgaon ' },
      {value: ' Rajsamand ' ,label:' Rajsamand ' },
      {value: ' Ramabai Nagar (Kanpur Dehat) ' ,label:' Ramabai Nagar (Kanpur Dehat) ' },
      {value: ' Ramanagara ' ,label:' Ramanagara ' },
      {value: ' Ramanathapuram ' ,label:' Ramanathapuram ' },
      {value: ' Ramban ' ,label:' Ramban ' },
      {value: ' Ramgarh ' ,label:' Ramgarh ' },
      {value: ' Rampur ' ,label:' Rampur ' },
      {value: ' Ranchi ' ,label:' Ranchi ' },
      {value: ' Ratlam ' ,label:' Ratlam ' },
      {value: ' Ratnagiri ' ,label:' Ratnagiri ' },
      {value: ' Rayagada ' ,label:' Rayagada ' },
      {value: ' Reasi ' ,label:' Reasi ' },
      {value: ' Rewa ' ,label:' Rewa ' },
      {value: ' Rewari ' ,label:' Rewari ' },
      {value: ' Ri Bhoi ' ,label:' Ri Bhoi ' },
      {value: ' Rohtak ' ,label:' Rohtak ' },
      {value: ' Rohtas ' ,label:' Rohtas ' },
      {value: ' Rudraprayag ' ,label:' Rudraprayag ' },
      {value: ' Rupnagar ' ,label:' Rupnagar ' },
      {value: ' Sabarkantha ' ,label:' Sabarkantha ' },
      {value: ' Sagar ' ,label:' Sagar ' },
      {value: ' Saharanpur ' ,label:' Saharanpur ' },
      {value: ' Saharsa ' ,label:' Saharsa ' },
      {value: ' Sahibganj ' ,label:' Sahibganj ' },
      {value: ' Saiha ' ,label:' Saiha ' },
      {value: ' Salem ' ,label:' Salem ' },
      {value: ' Samastipur ' ,label:' Samastipur ' },
      {value: ' Samba ' ,label:' Samba ' },
      {value: ' Sambalpur ' ,label:' Sambalpur ' },
      {value: ' Sangli ' ,label:' Sangli ' },
      {value: ' Sangrur ' ,label:' Sangrur ' },
      {value: ' Sant Kabir Nagar ' ,label:' Sant Kabir Nagar ' },
      {value: ' Sant Ravidas Nagar ' ,label:' Sant Ravidas Nagar ' },
      {value: ' Saran ' ,label:' Saran ' },
      {value: ' Satara ' ,label:' Satara ' },
      {value: ' Satna ' ,label:' Satna ' },
      {value: ' Sawai Madhopur ' ,label:' Sawai Madhopur ' },
      {value: ' Sehore ' ,label:' Sehore ' },
      {value: ' Senapati ' ,label:' Senapati ' },
      {value: ' Seoni ' ,label:' Seoni ' },
      {value: ' Seraikela Kharsawan ' ,label:' Seraikela Kharsawan ' },
      {value: ' Serchhip ' ,label:' Serchhip ' },
      {value: ' Shahdol ' ,label:' Shahdol ' },
      {value: ' Shahjahanpur ' ,label:' Shahjahanpur ' },
      {value: ' Shajapur ' ,label:' Shajapur ' },
      {value: ' Shamli ' ,label:' Shamli ' },
      {value: ' Sheikhpura ' ,label:' Sheikhpura ' },
      {value: ' Sheohar ' ,label:' Sheohar ' },
      {value: ' Sheopur ' ,label:' Sheopur ' },
      {value: ' Shimla ' ,label:' Shimla ' },
      {value: ' Shimoga ' ,label:' Shimoga ' },
      {value: ' Shivpuri ' ,label:' Shivpuri ' },
      {value: ' Shopian ' ,label:' Shopian ' },
      {value: ' Shravasti ' ,label:' Shravasti ' },
      {value: ' Sibsagar ' ,label:' Sibsagar ' },
      {value: ' Siddharthnagar ' ,label:' Siddharthnagar ' },
      {value: ' Sidhi ' ,label:' Sidhi ' },
      {value: ' Sikar ' ,label:' Sikar ' },
      {value: ' Simdega ' ,label:' Simdega ' },
      {value: ' Sindhudurg ' ,label:' Sindhudurg ' },
      {value: ' Singrauli ' ,label:' Singrauli ' },
      {value: ' Sirmaur ' ,label:' Sirmaur ' },
      {value: ' Sirohi ' ,label:' Sirohi ' },
      {value: ' Sirsa ' ,label:' Sirsa ' },
      {value: ' Sitamarhi ' ,label:' Sitamarhi ' },
      {value: ' Sitapur ' ,label:' Sitapur ' },
      {value: ' Sivaganga ' ,label:' Sivaganga ' },
      {value: ' Siwan ' ,label:' Siwan ' },
      {value: ' Solan ' ,label:' Solan ' },
      {value: ' Solapur ' ,label:' Solapur ' },
      {value: ' Sonbhadra ' ,label:' Sonbhadra ' },
      {value: ' Sonipat ' ,label:' Sonipat ' },
      {value: ' Sonitpur ' ,label:' Sonitpur ' },
      {value: ' South 24 Parganas ' ,label:' South 24 Parganas ' },
      {value: ' South Delhi ' ,label:' South Delhi ' },
      {value: ' South Garo Hills ' ,label:' South Garo Hills ' },
      {value: ' South Goa ' ,label:' South Goa ' },
      {value: ' South Sikkim ' ,label:' South Sikkim ' },
      {value: ' South Tripura ' ,label:' South Tripura ' },
      {value: ' South West Delhi ' ,label:' South West Delhi ' },
      {value: ' Sri Muktsar Sahib ' ,label:' Sri Muktsar Sahib ' },
      {value: ' Srikakulam ' ,label:' Srikakulam ' },
      {value: ' Srinagar ' ,label:' Srinagar ' },
      {value: ' Subarnapur (Sonepur) ' ,label:' Subarnapur (Sonepur) ' },
      {value: ' Sultanpur ' ,label:' Sultanpur ' },
      {value: ' Sundergarh ' ,label:' Sundergarh ' },
      {value: ' Supaul ' ,label:' Supaul ' },
      {value: ' Surat ' ,label:' Surat ' },
      {value: ' Surendranagar ' ,label:' Surendranagar ' },
      {value: ' Surguja ' ,label:' Surguja ' },
      {value: ' Tamenglong ' ,label:' Tamenglong ' },
      {value: ' Tarn Taran ' ,label:' Tarn Taran ' },
      {value: ' Tawang ' ,label:' Tawang ' },
      {value: ' Tehri Garhwal ' ,label:' Tehri Garhwal ' },
      {value: ' Thane ' ,label:' Thane ' },
      {value: ' Thanjavur ' ,label:' Thanjavur ' },
      {value: ' The Dangs ' ,label:' The Dangs ' },
      {value: ' Theni ' ,label:' Theni ' },
      {value: ' Thiruvananthapuram ' ,label:' Thiruvananthapuram ' },
      {value: ' Thoothukudi ' ,label:' Thoothukudi ' },
      {value: ' Thoubal ' ,label:' Thoubal ' },
      {value: ' Thrissur ' ,label:' Thrissur ' },
      {value: ' Tikamgarh ' ,label:' Tikamgarh ' },
      {value: ' Tinsukia ' ,label:' Tinsukia ' },
      {value: ' Tirap ' ,label:' Tirap ' },
      {value: ' Tiruchirappalli ' ,label:' Tiruchirappalli ' },
      {value: ' Tirunelveli ' ,label:' Tirunelveli ' },
      {value: ' Tirupur ' ,label:' Tirupur ' },
      {value: ' Tiruvallur ' ,label:' Tiruvallur ' },
      {value: ' Tiruvannamalai ' ,label:' Tiruvannamalai ' },
      {value: ' Tiruvarur ' ,label:' Tiruvarur ' },
      {value: ' Tonk ' ,label:' Tonk ' },
      {value: ' Tuensang ' ,label:' Tuensang ' },
      {value: ' Tumkur ' ,label:' Tumkur ' },
      {value: ' Udaipur ' ,label:' Udaipur ' },
      {value: ' Udalguri ' ,label:' Udalguri ' },
      {value: ' Udham Singh Nagar ' ,label:' Udham Singh Nagar ' },
      {value: ' Udhampur ' ,label:' Udhampur ' },
      {value: ' Udupi ' ,label:' Udupi ' },
      {value: ' Ujjain ' ,label:' Ujjain ' },
      {value: ' Ukhrul ' ,label:' Ukhrul ' },
      {value: ' Umaria ' ,label:' Umaria ' },
      {value: ' Una ' ,label:' Una ' },
      {value: ' Unnao ' ,label:' Unnao ' },
      {value: ' Upper Siang ' ,label:' Upper Siang ' },
      {value: ' Upper Subansiri ' ,label:' Upper Subansiri ' },
      {value: ' Uttar Dinajpur ' ,label:' Uttar Dinajpur ' },
      {value: ' Uttara Kannada ' ,label:' Uttara Kannada ' },
      {value: ' Uttarkashi ' ,label:' Uttarkashi ' },
      {value: ' Vadodara ' ,label:' Vadodara ' },
      {value: ' Vaishali ' ,label:' Vaishali ' },
      {value: ' Valsad ' ,label:' Valsad ' },
      {value: ' Varanasi ' ,label:' Varanasi ' },
      {value: ' Vellore ' ,label:' Vellore ' },
      {value: ' Vidisha ' ,label:' Vidisha ' },
      {value: ' Viluppuram ' ,label:' Viluppuram ' },
      {value: ' Virudhunagar ' ,label:' Virudhunagar ' },
      {value: ' Visakhapatnam ' ,label:' Visakhapatnam ' },
      {value: ' Vizianagaram ' ,label:' Vizianagaram ' },
      {value: ' Vyara ' ,label:' Vyara ' },
      {value: ' Warangal ' ,label:' Warangal ' },
      {value: ' Wardha ' ,label:' Wardha ' },
      {value: ' Washim ' ,label:' Washim ' },
      {value: ' Wayanad ' ,label:' Wayanad ' },
      {value: ' West Champaran ' ,label:' West Champaran ' },
      {value: ' West Delhi ' ,label:' West Delhi ' },
      {value: ' West Garo Hills ' ,label:' West Garo Hills ' },
      {value: ' West Kameng ' ,label:' West Kameng ' },
      {value: ' West Khasi Hills ' ,label:' West Khasi Hills ' },
      {value: ' West Siang ' ,label:' West Siang ' },
      {value: ' West Sikkim ' ,label:' West Sikkim ' },
      {value: ' West Singhbhum ' ,label:' West Singhbhum ' },
      {value: ' West Tripura ' ,label:' West Tripura ' },
      {value: ' Wokha ' ,label:' Wokha ' },
      {value: ' Yadgir ' ,label:' Yadgir ' },
      {value: ' Yamuna Nagar ' ,label:' Yamuna Nagar ' },
      {value: ' Yanam ' ,label:' Yanam ' },
      {value: ' Yavatmal ' ,label:' Yavatmal ' },
      {value: ' Zunheboto ' ,label:' Zunheboto ' },
      ];

class Cancer extends React.Component{
    constructor(props){
        super(props)
   
        this.state={
            show:false,
            modalIsOpen: false
        }
        this.state = {
            name:'',
            email: '',
            city: ''
  
         }

         
         
    }
    handleModal(){
        this.setState({show:false})
        
    }
   
    handleAPIModal(){
  
      this.hide_modal('myModal');

      this.show_modal('myModal1');
    }

    abc(){
      window.location.reload(false);
    }

    hide_modal(cnt)
    {    

      //  $('.modal-backdrop').removeClass('show');
      // $('#'+cnt).removeClass('show');
      // $('#'+cnt).hide();
      // console.warn(cnt)
    }
  
    show_modal(cnt)
    {
      
      //  $('.modal-backdrop').addClass('show');
      //  $('#'+cnt).addClass('show');
      // $('#'+cnt).show();
  
    }

    handleChange = (e) =>{
      this.setState({city: e.value});
      console.warn(this.state)
      }
    

    changeHandler = (e) => {
      
        this.setState({[e.target.name] : e.target.value})
        
       console.warn(this.state)
        
      }


     submitHandler = (e) => {
        e.preventDefault();
        
        const form=document.forms['myForm']
     
        fetch('https://script.google.com/macros/s/AKfycbwLU6gL7qYRerqx9wsFdQbDI6yRXAGQZviBL3oWhmynGyhZ5pXVabcmEjJWP5AOzjLg/exec',
        {
        method: 'post',
        body: new FormData(form),
      
     })  .then(response=>{
      //  window.location.reload(false);
       this.handleAPIModal();
      }
     )
         .catch(error=>console.warn(error))
         
      }

    render(){
        const {name,email} = this.state;
        const {city} =this.state.city;
        
        return (
          <div className="App">
            <header id="topnav" className="defaultscroll sticky">
              <div className="container">
                <a className="logo" href="index.html">
                  <span className="logo-light-mode">
                    <img
                      src="asset/img/logo-colorful.png"
                      className="l-dark"
                      alt=""
                    />
                    <img src="asset/img/Logo.png" className="l-light" alt="" />
                  </span>
                  <img
                    src="asset/img/Logo.png"
                    className="logo-dark-mode"
                    alt=""
                  />
                </a>

                <div className="menu-extras">
                  <div className="menu-item">
                    <a
                      className="navbar-toggle"
                      id="isToggle"
                      onClick="toggleMenu()"
                    >
                      <div className="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </header>

            <section
              className="bg-half-170 d-table w-100 overflow-hidden"
              style={{
                // backgroundImage: `url(${bannerImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
              id="home"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7 col-md-7">
                    <div className="title-heading mt-4">
                      <h1 className="heading title-dark text-white mb-3">
                        Early Cancer detection can save over 3 lakh lives, every
                        year in India
                      </h1>
                      <p className="para-desc para-dark text-light">
                        Download Heme Health to store and access all your health
                        records and get FREE cancer screening*.{" "}
                      </p>
                      <div className="mt-4 pt-2">
                        <a
                          onClick={() => this.handleModal()}
                          data-bs-target="#myModal"
                          data-bs-toggle="modal"
                          href="#"
                          className="btn btn-primary1 m-1"
                        >
                          Get FREE Cancer Screening*
                        </a>
                        
                      </div>

                      <div className="mt-4">
                        <a href="javascript:void(0)">
                          <img
                            src="asset/images/app/app.png"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>

                        <a href="javascript:void(0)">
                          <img
                            src="asset/images/app/playstore.png"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div className="classic-app-image position-relative"></div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section overflow-hidden" id="solution">
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <div className="section-title mb-4 pb-2">
                    {/* <span className="badge bg-primary rounded-pill mb-2">Features</span> */}
                    <h4 className="title mb-4">
                      Your digital health locker for Cancer
                    </h4>
                    <p className="text-muted para-desc mx-auto mb-0">
                      Take ownership of your health data{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="container mt-100 mt-60">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-5">
                    <div className="text-center text-md-start">
                      <img
                        src="asset/img/feature/feature-1.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-7 mt-5 mt-sm-0">
                    <div className="section-title">
                      <h1 className="title mb-3">
                        All your lab reports in <br />
                        one place
                      </h1>
                      <p className="para-desc text-muted">
                        Link your Ayushman Bharat Digital Health ID and access
                        all your lab reports. Alternatively, email them to us or
                        upload them manually. Our unique ‘healthlink’ technology
                        means you can instantly access records from thousands of
                        pathology labs in India.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container mt-100 mt-60">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
                    <div className="section-title">
                      <h1 className="title mb-3">
                        Build your ‘digital health history’
                      </h1>
                      <p className="para-desc text-muted">
                        We use cutting edge natural language processing to build
                        your personalised ‘Digital Health History’. We generate
                        health graphs by extracting relevant information from
                        your lab tests to give you a clear picture of how your
                        health is changing over time. For example, you can
                        visualise your cancer markers across multiple reports in
                        a single graph!
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-5 order-1 order-md-2">
                    <div className="text-center text-md-end">
                      <img
                        src="asset/img/feature/feature-2.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="container mt-100 mt-60">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-5">
                    <div className="text-center text-md-start">
                      <img
                        src="asset/img/feature/feature-3.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-7 mt-5 mt-sm-0">
                    <div className="section-title">
                      <h1 className="title mb-3">
                        Understand what your <br /> reports mean
                      </h1>
                      <p className="para-desc text-muted">
                        Test results can be scary and difficult to understand.
                        We demystify your lab reports - learn how the test was
                        carried out, what the individual parameters mean, the
                        accuracy of test results and much more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="section "
              style={{
                // backgroundImage: `url(${footerImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: "100%",
              }}
              id="beta"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12 text-center">
                    <div className="title-heading mt-4">
                      {/* <div className="alert alert-transparent alert-pills shadow" role="alert">
                                <span className="badge rounded-pill bg-primary me-1">Heme</span>
                                <span className="content">Your Health Records</span>
                            </div> */}
                      <h1 className="heading title-dark text-white mb-3">
                        We positively impact lives using technology
                      </h1>
                      <p className="para-desc para-dark text-light mx-auto">
                        <b>Download Heme Health</b> app to securely store,
                        access and analyse your laboratory reports, for{" "}
                        <b>FREE!</b>
                      </p>
                      <div className="mt-4 pt-2">
                        <a
                          //onClick={this.submitHandler}
                          href="#"
                          className="btn btn-primary1 m-1"
                        >
                          Join Our Beta Program
                        </a>
                      </div>
                      <div className="mt-4">
                        <a href="javascript:void(0)">
                          <img
                            src="asset/images/app/app.png"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>

                        <a href="javascript:void(0)">
                          <img
                            src="asset/images/app/playstore.png"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <footer className="footer">
              <div className="footer-py-30 footer-bar">
                <div className="container text-center">
                  <div className="row align-items-center">
                    <div className="col-sm-12">
                      <div className="text-sm">
                        <p className="mb-0">
                          © Copyright 2022 HEME |{" "}
                          <Link className="text-foot" to="/Privacy">
                            Privacy Policy
                          </Link>{" "}
                          |{" "}
                          <Link className="text-foot" to="/Privacy">
                            Terms and Condition
                          </Link>
                        </p>
                        <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                          <li className="list-inline-item">
                            <a href="javascript:void(0)" className="rounded">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-facebook fea icon-sm fea-social"
                              >
                                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                              </svg>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="javascript:void(0)" className="rounded">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-instagram fea icon-sm fea-social"
                              >
                                <rect
                                  x="2"
                                  y="2"
                                  width="20"
                                  height="20"
                                  rx="5"
                                  ry="5"
                                ></rect>
                                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                <line
                                  x1="17.5"
                                  y1="6.5"
                                  x2="17.51"
                                  y2="6.5"
                                ></line>
                              </svg>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="javascript:void(0)" className="rounded">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-twitter fea icon-sm fea-social"
                              >
                                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                              </svg>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="javascript:void(0)" className="rounded">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-linkedin fea icon-sm fea-social"
                              >
                                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                <rect x="2" y="9" width="4" height="12"></rect>
                                <circle cx="4" cy="4" r="2"></circle>
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>


           
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog">

 
    <div class="modal-content">
      <div class="modal-header">
        
        <h4 class="modal-title">Provide Your Details</h4>
      </div>
      <div class="modal-body">
      <div
                              className="card border-0 mt-5"
                              style={{ zIndex: "1" }}
                            >
                              <div className="card-body p-0">
                                <form
                                  method="post"
                                  id="myForm"
                                   onSubmit={this.submitHandler.bind(this)}
                                >
                                  <p id="demo"></p>
                                  <p id="error-msg" className="mb-0"></p>
                                  <div id="simple-msg"></div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="mb-3">
                                        {/* <p id="demo"></p> */}
                                        <label className="form-label">
                                          Your Name{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-icon position-relative">
                                          <i
                                            data-feather="mail"
                                            className="fea icon-sm icons"
                                          ></i>
                                          <input
                                            onChange={this.changeHandler}
                                            value={name}
                                            name="name"
                                            id="name"
                                            type="text"
                                            className="form-control ps-5"
                                            placeholder="Name :"
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="mb-3">
                                        {/* <p id="demo"></p> */}
                                        <label className="form-label">
                                          Your Email{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-icon position-relative">
                                          <i
                                            data-feather="mail"
                                            className="fea icon-sm icons"
                                          ></i>
                                          <input
                                            onChange={this.changeHandler}
                                            value={email}
                                            name="email"
                                            id="email"
                                            type="email"
                                            className="form-control ps-5"
                                            placeholder="Email :"
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="mb-3">
                                        {/* <p id="demo"></p> */}
                                        <label className="form-label">
                                         City{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-icon position-relative">
                                          <i
                                            data-feather="mail"
                                            className="fea icon-sm icons"
                                          ></i>
                                           <Select
                                            value={city}
                                            onChange={this.handleChange}
                                            options={options}
                                            name="city"
                                            id="city"
                                            maxMenuHeight="50"
                                            
                                            />
                                          
                                        </div>
                                      </div>
                                    </div>
                                  
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="d-grid">
                                        <button
                                        // id="myForm"
                                        //onClick={this.submitHandler.bind(this)}
                                         //onClick={this.show_modal}
                                          type="submit"
                                          className="btn btn-primary1"
                                        >
                                          Join our Beta & Avail FREE Screening*
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
      </div>
      <div style={{color:"gray"}} class="modal-footer">
      *Terms & Conditions Apply.
      </div>
    </div>

  </div>
</div>

<div id="myModal1" class="modal fade" role="dialog">
  <div class="modal-dialog">

 
    <div class="modal-content">
      <div class="modal-header">
        
        
      </div>
      <div class="modal-body">
      <img src="asset/img/success-icon.png" alt="" style={{display: "block",marginLeft: "auto", marginRight: "auto"}} />
        <p style={{textAlign:"center"}}>Thank you for joining our Beta program</p>
        <p style={{textAlign:"center",color:"gray"}}>In the coming weeks, we will notify you via email on our Beta launch and your FREE cancer screening.</p>
      </div>
      <div class="modal-footer">
        <button onClick={this.abc} type="button" style={{display: "block",marginLeft: "auto", marginRight: "auto"}} className="btn btn-primary1" data-dismiss="modal">Ok, thank you</button>
      </div>
    </div>

  </div>
</div>


            <div
              className="offcanvas offcanvas-end bg-white shadow"
              tabindex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header p-4 border-bottom">
                <h5 id="offcanvasRightLabel" className="mb-0">
                  <img src="" height="24" className="light-version" alt="" />
                  <img src="" height="24" className="dark-version" alt="" />
                </h5>
                <button
                  type="button"
                  className="btn-close d-flex align-items-center text-dark"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <i className="uil uil-times fs-4"></i>
                </button>
              </div>
              <div className="offcanvas-body p-4">
                <div className="row">
                  <div className="col-12">
                    <img
                      src="asset/images/contact.svg"
                      className="img-fluid d-block mx-auto"
                      style={{ maxWidth: "256px" }}
                      alt=""
                    />
                    <div className="card border-0 mt-5" style={{ zIndex: "1" }}>
                      <div className="card-body p-0">
                        <form
                          method="post"
                          name="myForm"
                          onsubmit="return validateForm()"
                        >
                          <p id="error-msg" className="mb-0"></p>
                          <div id="simple-msg"></div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Your Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <i
                                    data-feather="user"
                                    className="fea icon-sm icons"
                                  ></i>
                                  <input
                                    name="name"
                                    id="name"
                                    type="text"
                                    className="form-control ps-5"
                                    placeholder="Name :"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Your Email{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <i
                                    data-feather="mail"
                                    className="fea icon-sm icons"
                                  ></i>
                                  <input
                                    name="email"
                                    id="email"
                                    type="email"
                                    className="form-control ps-5"
                                    placeholder="Email :"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="mb-3">
                                <label className="form-label">Subject</label>
                                <div className="form-icon position-relative">
                                  <i
                                    data-feather="book"
                                    className="fea icon-sm icons"
                                  ></i>
                                  <input
                                    name="subject"
                                    id="subject"
                                    className="form-control ps-5"
                                    placeholder="subject :"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="mb-3">
                                <label className="form-label">
                                  Comments{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <i
                                    data-feather="message-circle"
                                    className="fea icon-sm icons clearfix"
                                  ></i>
                                  <textarea
                                    name="comments"
                                    id="comments"
                                    rows="4"
                                    className="form-control ps-5"
                                    placeholder="Message :"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="d-grid">
                                <button
                                  type="submit"
                                  id="submit"
                                  name="send"
                                  className="btn btn-primary"
                                >
                                  Send Message
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="offcanvas-footer p-4 border-top text-center">
                <ul className="list-unstyled social-icon social mb-0">
                  <li className="list-inline-item mb-0">
                    <a
                      href="https://dribbble.com/"
                      target="_blank"
                      className="rounded"
                    >
                      <i
                        className="uil uil-dribbble align-middle"
                        title="dribbble"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item mb-0">
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      className="rounded"
                    >
                      <i
                        className="uil uil-facebook-f align-middle"
                        title="facebook"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item mb-0">
                    <a
                      href="https://www.instagram.com/"
                      target="_blank"
                      className="rounded"
                    >
                      <i
                        className="uil uil-instagram align-middle"
                        title="instagram"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item mb-0">
                    <a
                      href="https://twitter.com/"
                      target="_blank"
                      className="rounded"
                    >
                      <i
                        className="uil uil-twitter align-middle"
                        title="twitter"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item mb-0">
                    <a href="" className="rounded">
                      <i
                        className="uil uil-envelope align-middle"
                        title="email"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item mb-0">
                    <a href="" target="_blank" className="rounded">
                      <i
                        className="uil uil-globe align-middle"
                        title="website"
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            

            {/* <div className="cookie-popup bg-white shadow rounded py-3 px-4">
            <p className="text-muted mb-0">This website uses cookies to provide you with a great user experience. By using it, you accept our <a href="" target="_blank" className="text-success h6">use of cookies</a></p>
            <div className="cookie-popup-actions text-end">
                <button><i className="uil uil-times text-dark fs-4"></i></button>
            </div>
        </div> */}
            <a
              href="#"
              onClick="topFunction()"
              id="back-to-top"
              className="back-to-top fs-5"
            >
              <i
                data-feather="arrow-up"
                className="fea icon-sm icons align-middle"
              ></i>
            </a>
          </div>
        );
    }
}
export default Cancer;
