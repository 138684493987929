import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import "./Contest.css";
import "./ReferWin.css";

const ContestTerms = () => {
  const location = useLocation();

  useEffect(() => {
    const metaDescription =
      "Before participating in our Refer & Win contest, please review the terms and conditions outlined on this page. By entering the contest, you agree to abide by the rules and guidelines set forth by Heme Health. This includes eligibility requirements, referral guidelines, and prize details. We want to ensure a fair and enjoyable experience for all participants, so please take a few moments to review the terms and conditions before referring your friends and family.";
    const descriptionTag = document.querySelector('meta[name="description"]');
    descriptionTag.setAttribute("content", metaDescription);
  }, [location.pathname]);

  const reloadPage = () => {
    window.location.reload(false);
  };

  const toggleMenu = () => {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  };

  return (
    <div className="App">
      <head>
        <title>Refer & Win Contest Terms & Conditions</title>
      </head>

      <header id="topnav" className="defaultscroll sticky">
        <div className="container">
          <a className="logo" href="/">
            <img
              src="asset/images/logo.svg"
              className="logo-light-mode"
              id="logo-image"
              alt=""
            />
            <img
              src="asset/images/logo.svg"
              className="logo-dark-mode"
              alt=""
            />
          </a>

          <div className="menu-extras">
            <div className="menu-item">
              <a
                className="navbar-toggle"
                id="isToggle"
                onClick={() => toggleMenu()}
              >
                <img
                  src="asset/images/icon/hemburger-icon.svg"
                  style={{ marginTop: 25 }}
                />
              </a>
            </div>
          </div>

          <div id="navigation">
            <ul className="navigation-menu">
              <li className="parent-parent-menu-item">
                <a className="sub-menu-item" href="/">
                  For Patients
                </a>
              </li>
              <li className="parent-parent-menu-item" style={{}}>
                <a className="sub-menu-item" href="/ForLabs">
                  For Labs
                </a>
              </li>
              <li className="parent-parent-menu-item" style={{}}>
                <a
                  className="sub-menu-item active"
                  style={{
                    background: "linear-gradient(to right,#E50493, #C7007E)",
                    paddingLeft: 30,
                    paddingRight: 30,
                    borderRadius: 45,
                    height: 10,
                    paddingTop: 10,
                    paddingBottom: 33,
                    margin: 15,
                    color: "#fff",
                  }}
                >
                  Refer & Win
                </a>
              </li>
              {/* <li className="parent-parent-menu-item" style={{}}><a className="sub-menu-item">For Doctors</a></li> */}
            </ul>
          </div>
        </div>
      </header>

      <section>
        <div style={{ height: 75 }}></div>
      </section>

      {/* for desktop view */}
      <section
        class="bg-home d-flex align-items-center d-md-block d-none"
        style={{
          backgroundImage: `url("asset/images/refer-win/banner-image.png")`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: 500,
          maxWidth: "100%",
        }}
        id="home"
      >
        <div className="container refer-win-left">
          <div className="row flex-column-reverse flex-md-row align-items-center mt-5 mt-sm-0 refer-win-left-inner">
            <div className="col-md-6">
              <div className="title-heading text-center text-md-start">
                <h4
                  className="heading mb-3 mt-2"
                  style={{
                    color: "#fff",
                  }}
                >
                  Refer & Win Contest has Concluded
                </h4>
                <p
                  id="bannerP"
                  style={{
                    color: "#fff",
                  }}
                  className="mb-0 para-desc mx-auto ms-md-auto"
                >
                  Stay Tuned for the Winners Announcement!
                </p>

                <div className="mt-4 pt-2">
                  <a href="https://bit.ly/3xAMh9r">
                    <img
                      src="asset/images/app/playstore.svg"
                      className="m-1"
                      height="50"
                      alt=""
                    />
                  </a>
                  <a href="https://apple.co/3CLxrQu">
                    <img
                      src="asset/images/app/appstore.svg"
                      className="m-1"
                      height="50"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* for mobile view */}
      <div className="d-block d-md-none m-10">
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            backgroundColor: "#f88379",
            color: "#FFF",
          }}
        >
          Refer & Win Contest has Concluded
        </div>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            backgroundColor: "#f88379",
            color: "#FFF",
          }}
        >
          Stay Tuned for the Winners Announcement!
        </div>
        <img
          src="asset/images/refer-win/mobile-contest-banner.png"
          alt=""
          className="img-fluid"
        />
      </div>
      <div className="d-block d-sm-none">
        <div
          className="d-flex justify-content-center pb-4 pt-4"
          style={{ backgroundColor: "#2c408e", overflow: "hidden" }}
        >
          <a href="https://bit.ly/3xAMh9r">
            <img
              src="asset/images/app/playstore.svg"
              className="m-1"
              height="50"
              alt=""
            />
          </a>
          <a href="https://apple.co/3CLxrQu">
            <img
              src="asset/images/app/appstore.svg"
              className="m-1"
              height="50"
              alt=""
            />
          </a>
        </div>
      </div>

      <section>
        <div style={{ height: 50 }}></div>
      </section>

      <section>
        <div style={{ height: 50 }}></div>
      </section>

      <section>
        <div
          className="container"
          style={{
            backgroundColor: "#F5FBFC",
            // backgroundColor: 'red',
            padding: 20,
            marginBottom: 100,
            borderRadius: 16,
          }}
        >
          <div className="p-100">
            <h1 style={{ fontSize: 40 }}>*Contest Terms & Conditions</h1>
            <ul
              className="arrow-list"
              style={{
                fontSize: 18,
                letterSpacing: 0,
                color: "#707C80",
              }}
            >
              <div style={{ height: 20 }}></div>
              <li>
                The contest is open to all Heme Health users who have registered
                using their mobile number and are at least 18 years old and are
                located in India.
              </li>
              <li>
                To be a part of the contest, you must get at least one
                successful referral. This means that a user must create a Heme
                Health account using your unique referral link or code.
              </li>
              <li>
                Users can track their referrals by accessing the referrals
                section in the app, where they can view the list of successful
                referrals.
              </li>
              <li>
                The leaderboard will be accessible to all participants and
                updated in real-time to ensure transparency.
              </li>
              <li>The contest will end at 11:59 PM IST on 28th May 2023.</li>
              <li>
                The winners will be selected based on the number of successful
                referrals they make, more details below. The prizes for the
                contest are as follows:
              </li>
              <li>
                <span style={{ fontWeight: "bold", color: "#525252" }}>
                  1st Place:
                </span>{" "}
                Signed Jersey, ball & miniature bat.
              </li>
              <li>
                <span style={{ fontWeight: "bold", color: "#525252" }}>
                  2nd to 5th Place:
                </span>{" "}
                will get signed Jersey.
              </li>
              <li>
                <span style={{ fontWeight: "bold", color: "#525252" }}>
                  6th to 10th Place:
                </span>{" "}
                will get signed cricket ball.
              </li>
              <li>
                <span style={{ fontWeight: "bold", color: "#525252" }}>
                  11th to 15th Place:
                </span>{" "}
                will get signed miniature bat (Any 5 random participants will be
                picked from 11th to 30th position on the leaderboard)
              </li>
              <li>
                If multiple users have the same number of referrals, their
                position on the leaderboard will be determined by the time at
                which they received their first referral, with the user who
                achieved their first referral earliest taking the higher
                position.
              </li>
              <li>
                The winners will be announced on the Heme Health’s Twitter
                handle @hemehealth and contacted via email and/or phone number
                registered with their Heme Health account.
              </li>
              <li>
                If a winner does not respond within 48 hours of the
                announcement, the prize will be forfeited, and another winner
                will be selected.
              </li>
              <li style={{ fontWeight: "bold", color: "#525252" }}>
                Participation in this contest is limited to users located in
                India only. The prizes are non-transferable, and no cash
                alternative will be offered. The delivery of the prizes will be
                done in India only. The images used for prizes are for
                representation only, and the actual product may differ.
              </li>
              <li>
                Heme Health's decision regarding the winners of the contest will
                be final and binding, and no correspondence will be entertained
                in this regard.
              </li>
              <li>
                Heme Health shall not be responsible or liable for any damages
                or losses that may occur to the participants or the winners,
                including but not limited to, any loss of data, profits, or any
                other consequential damages or losses, arising from or in
                connection with the contest.
              </li>
              <li>
                Heme Health reserves the right to modify or cancel the contest
                at any time without prior notice.
              </li>
              <li>
                In case of queries please reach out to{" "}
                <a
                  href="mailto:info@hemehealth.io"
                  style={{
                    color: "#7ABA56",
                  }}
                >
                  info@hemehealth.io
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Footer />

      <div
        id="myModal1"
        class="modal fade"
        role="dialog"
        style={{ backgroundColor: "#093A78", opacity: 1 }}
      >
        <div class="modal-dialog">
          <div class="modal-content" style={{ backgroundColor: "#3EC1E5" }}>
            <div class="modal-header"></div>
            <div class="modal-body">
              <img
                src="asset/images/icon/thanks.svg"
                alt=""
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <p
                className="text-light"
                style={{ textAlign: "center", fontSize: 40 }}
              >
                Thank you!
              </p>
              <p
                className="text-light"
                style={{ textAlign: "center", fontSize: 24 }}
              >
                We’ll get in touch as soon as we can.
              </p>
            </div>
            <div class="modal-footer" style={{ borderTop: 0 }}>
              <button
                onClick={reloadPage}
                type="button"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: 24,
                  width: "35%",
                  borderWidth: 3,
                }}
                className="btn btn-primary1"
                data-dismiss="modal"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestTerms;
