import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { scrollToTop, scrollWithOffset } from '../Config/utilities';
import { HashLink, NavHashLink } from 'react-router-hash-link';

export default function Footer() {
  return (
    <div>
       <footer className="footer">
              <div className="footer-py-15 footer-bar" style={{padding:15}}>
                <div className="container text-center">
                {/* <div className="row align-items-center">
                    <div className="col-sm-12">
                      <div className="text-sm" style={{}}>
                        <p className="mb-0">
                          <Link to="/" exact activeClassName="active" className="text-foot" style={{margin: 10}} onClick={scrollToTop}>For Patients</Link>
                            |
                          <Link to="/ForLabs" activeClassName="active" className="text-foot" style={{margin: 10}} onClick={scrollToTop}>For Labs</Link>
                        </p>
                        
                        
                      </div>
                    </div>
                  </div> */}
                  <div className="row align-items-center">
                    <div className="col-sm-12">
                      <div className="text-sm">
                        <p className="mb-0" style={{fontSize:12}}>
                        <HashLink
                        scroll={el => scrollWithOffset(el, 150)}
                        to="/Privacy#terms-section"  className="text-foot" style={{padding:5}}>Terms and Condition</HashLink>
                          |
                          <HashLink 
                          scroll={el => scrollWithOffset(el, 20)}
                          to="/Privacy#privacy-section" className="text-foot" style={{padding:5}}>Privacy Policy</HashLink>
                          |
                          <span style={{padding:5}}>
                            © Copyright 2024, HemeHealth v1.0.3
                          </span>
                          
                        </p>
                        
                       </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
    </div>
  );
}
