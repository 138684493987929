//import logo from './logo.svg';
//import './App.css';

import Home from "./pages/home";
import About from "./pages/aboutheme";
//import './style.min.css';
import HttpsRedirect from 'react-https-redirect';
import Features from "./pages/features";
import Solution from "./pages/solutions";
import Contact from "./pages/contact";
import Beta from "./pages/beta";
import Cancer from "./pages/cancer";
import Heart from "./pages/heart";
import Generalhealth from "./pages/generalhealth";
import Diabetes from "./pages/diabetes";
import HomeNew from "./pages/HomeNew";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import Privacy from "./pages/Privacy";
import ForLabs from "./pages/ForLabs";
import ReferWin from "./pages/ReferWin";
import ContestTerms from "./pages/ContestTerms";
import MainPage from "./pages/MainPage";



function App() {
  return (
    <HttpsRedirect>
      <Router>
        <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route path="/aboutheme" element={<About />} />
        <Route path="/features" element={<Features />} />
        <Route path="/solutions" element={<Solution />} />
        <Route path="/beta" element={<Beta />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Cancer" element={<Cancer />} />
        <Route path="/Heart" element={<Heart />} />
        <Route path="/Generalhealth" element={<Generalhealth />} />
        <Route path="/Diabetes" element={<Diabetes />} />
        <Route path="/HomeNew" element={<HomeNew />} />
        <Route path="/ForLabs" element={<ForLabs />} />
        {/* <Route path="/ReferWin" element={<ReferWin />} /> */}
        {/* <Route path="/ContestTerms" element={<ContestTerms />} /> */}
        </Routes>
      </Router>
    {/* <Home />
    <About />
    <Features />
    <Solution />
   <Beta />
    <Contact /> */}
    </HttpsRedirect>
  );
}

export default App;
