import React from "react";

class Features extends React.Component{
    render(){
        return(
           <>
           <div>
           <h1>Feature Page</h1>
           </div>
           </>
        );
    }
}

export default Features;