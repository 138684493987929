// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1024px) {
  .custom-carousel-inner-container{
    height: 550px;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-refer-win-image{
    display: none;
  }

  .big-device-refer-win-image{
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-refer-win-image{
    display: block;
    width: 100%;
  }
  .big-device-refer-win-image{
    display: none;
  }
  .carousel-img-refer-win{
    width: 95% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,cAAc;IACd,WAAW;EACb;AACF;;AAEA;EACE;IACE,cAAc;IACd,WAAW;EACb;EACA;IACE,aAAa;EACf;EACA;IACE,qBAAqB;EACvB;AACF","sourcesContent":["@media only screen and (min-width: 1024px) {\n  .custom-carousel-inner-container{\n    height: 550px;\n  }\n}\n\n@media only screen and (min-width: 768px) {\n  .mobile-refer-win-image{\n    display: none;\n  }\n\n  .big-device-refer-win-image{\n    display: block;\n    width: 100%;\n  }\n}\n\n@media only screen and (max-width: 767px) {\n  .mobile-refer-win-image{\n    display: block;\n    width: 100%;\n  }\n  .big-device-refer-win-image{\n    display: none;\n  }\n  .carousel-img-refer-win{\n    width: 95% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
