import React from "react";
import { Link } from 'react-router-dom';

class About extends React.Component{
    render(){
        return(
           <>
           <div className="App">
           
           <div id="preloader">
               <div id="status">
                   <div className="spinner">
                       <div className="double-bounce1"></div>
                       <div className="double-bounce2"></div>
                       <div className="double-bounce3"></div>
                       <div className="double-bounce4"></div>
                   </div>
               </div>
           </div>
          
           
          
           <header id="topnav" className="defaultscroll sticky">
               <div className="container">
                  
                   <a className="logo" href="index.js">
                      
                       <img src="asset/images/heme_logo.png" height="24" className="logo-light-mode" alt=""/><img src="asset/images/heme.png" height="24" className="logo-light-mode" alt="" />
                       <img src="asset/images/heme_logo.png" height="24" className="logo-dark-mode" alt=""/>
                   </a>
                  
   
                   <div className="menu-extras">
                       <div className="menu-item">
                           
                           <a className="navbar-toggle" id="isToggle" onclick="toggleMenu()">
                               <div className="lines">
                                   <span></span>
                                   <span></span>
                                   <span></span>
                               </div>
                           </a>
                           
                       </div>
                   </div>
   
                   
                   <ul className="buy-button list-inline mb-0">
                       <li className="list-inline-item mb-0">
                           <a href="javascript:void(0)" className="btn btn-icon btn-soft-light">
                               <img src="asset/images/app/app-store.png" className="avatar avatar-ex-small p-1" alt=""/>
                           </a>
                       </li>
                       
                       <li className="list-inline-item mb-0">
                           <a href="javascript:void(0)" className="btn btn-icon btn-soft-light">
                               <img src="asset/images/app/play-store.png" className="avatar avatar-ex-small p-1" alt=""/>
                           </a>
                       </li>
                   </ul>
                  
           
                   <div id="navigation">
                      
                       <ul className="navigation-menu">
                           <li><Link to="/">Home</Link></li>
                           <li><Link to="/aboutheme">About Heme</Link></li>
                           <li><Link to="/features">Features</Link></li>
                           <li><Link to="/solutions">Solution</Link></li>
                           <li><Link to="/beta">Join Our Beta</Link></li>
                           <li><Link to="/contact">Contact</Link></li>
                           
                           
                           {/* <li><a href="" className="">About Heme</a></li>
                         
                           
                           <li><a href="" className="">Features</a></li>
                           <li><a href="" className="">Solution</a></li>
                           <li><a href="" className="">Join Our Beta</a></li>
                           <li><a href="" className="">Contact Us</a></li> */}
                           
                         
                       </ul>
                   </div>
               </div>
           </header>
           
         
         
           <section className="section overflow-hidden">
            
   
               
   
               <div className="container mt-100 mt-60">
                   <div className="row align-items-center">
                       <div className="col-lg-6 col-md-5">
                           <div className="app-feature-shape-left position-relative">
                               <div className="text-center text-md-start">
                                   <img src="asset/images/app/classic04.png" className="img-fluid" alt=""/>
                               </div>
                           </div>
                       </div>
   
                       <div className="col-lg-6 col-md-7 mt-5 mt-sm-0">
                           <div className="section-title">
                               <h1 className="title mb-3">Understand what your  <br/> reports mean</h1>
                               <p className="para-desc text-muted">Test results can be scary and difficult to understand. We demystify your lab reports - learn how the test was carried out, what the individual parameters mean, the accuracy of test results and much more. </p>
                               
                               <div className="mt-4">
                                   <a href="javascript:void(0)" className="btn btn-primary">Read More <i className="uil uil-angle-right-b"></i></a>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
   
   
               <div className="container mt-100 mt-60">
                   <div className="row align-items-center">
                       <div className="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
                           <div className="section-title">
                               <h1 className="title mb-3">Find anything instantly</h1>
                               <p className="para-desc text-muted">No need to manually search for reports from multiple folders, files or emails. Just type in any test parameter, or test name and our intelligent search will find all the relevant test results for your doctor to review in seconds. </p>
                               <div className="mt-4">
                                   <a href="javascript:void(0)" className="btn btn-primary">Learn More <i className="uil uil-angle-right-b"></i></a>
                               </div>
                           </div>
                       </div>
   
                       <div className="col-lg-6 col-md-5 order-1 order-md-2">
                           <div className="app-feature-shape-right position-relative">
                               <div className="text-center text-md-end">
                                   <img src="asset/images/app/classic03.png" className="img-fluid" alt=""/>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
   
   
            
   {/* 
               <div className="container mt-100 mt-60 pb-md-5 mb-md-5">
                   <div className="row justify-content-center">
                       <div className="col-12 text-center">
                           <div className="section-title mb-4 pb-2">
                               <span className="badge rounded-pill bg-success mb-2">Reviews</span>
                               <h4 className="title mb-4">What our users says !</h4>
                               <p className="text-muted para-desc mx-auto mb-0">Start using <span className="text-primary fw-bold">Heme App</span> it can give your health history,health related document and statistics</p>
                           </div>
                       </div>
                   </div>
   
                   <div className="row justify-content-center">
                       <div className="col-lg-12 mt-4">
                           <div className="tiny-three-item">
                               <div className="tiny-slide">
                                   <div className="d-flex client-testi m-2">
                                       <img src="asset/images/client/01.jpg" className="avatar avatar-small client-image rounded shadow" alt=""/>
                                       <div className="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul className="list-unstyled mb-0">
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                           </ul>
                                           <p className="text-muted mt-2">" Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum "</p>
                                           <h6 className="text-primary">- Client Name <small className="text-muted">C.E.O</small></h6>
                                       </div>
                                   </div>
                               </div>
                               
                               <div className="tiny-slide">
                                   <div className="d-flex client-testi m-2">
                                       <img src="asset/images/client/02.jpg" className="avatar avatar-small client-image rounded shadow" alt=""/>
                                       <div className="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul className="list-unstyled mb-0">
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star-half text-warning"></i></li>
                                           </ul>
                                           <p className="text-muted mt-2">" Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum "</p>
                                           <h6 className="text-primary">- Client Name <small className="text-muted">M.D</small></h6>
                                       </div>
                                   </div>
                               </div>
   
                               <div className="tiny-slide">
                                   <div className="d-flex client-testi m-2">
                                       <img src="asset/images/client/03.jpg" className="avatar avatar-small client-image rounded shadow" alt=""/>
                                       <div className="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul className="list-unstyled mb-0">
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                           </ul>
                                           <p className="text-muted mt-2">" Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum. "</p>
                                           <h6 className="text-primary">- Client Name <small className="text-muted">P.A</small></h6>
                                       </div>
                                   </div>
                               </div>
   
                               <div className="tiny-slide">
                                   <div className="d-flex client-testi m-2">
                                       <img src="asset/images/client/04.jpg" className="avatar avatar-small client-image rounded shadow" alt=""/>
                                       <div className="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul className="list-unstyled mb-0">
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                           </ul>
                                           <p className="text-muted mt-2">" Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum. "</p>
                                           <h6 className="text-primary">- Client Name <small className="text-muted">Manager</small></h6>
                                       </div>
                                   </div>
                               </div>
   
                               <div className="tiny-slide">
                                   <div className="d-flex client-testi m-2">
                                       <img src="asset/images/client/05.jpg" className="avatar avatar-small client-image rounded shadow" alt=""/>
                                       <div className="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul className="list-unstyled mb-0">
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                           </ul>
                                           <p className="text-muted mt-2">" Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum. "</p>
                                           <h6 className="text-primary">- Client Name <small className="text-muted">Developer</small></h6>
                                       </div>
                                   </div>
                               </div>
   
                               <div className="tiny-slide">
                                   <div className="d-flex client-testi m-2">
                                       <img src="asset/images/client/06.jpg" className="avatar avatar-small client-image rounded shadow" alt=""/>
                                       <div className="flex-1 content p-3 shadow rounded bg-white position-relative">
                                           <ul className="list-unstyled mb-0">
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                               <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                           </ul>
                                           <p className="text-muted mt-2">" Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum Loreum Ipsum. "</p>
                                           <h6 className="text-primary">- Client Name <small className="text-muted">Designer</small></h6>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div> */}
           </section>
         
           <section className="section bg-primary bg-gradient">
               <div className="bg-overlay bg-overlay-white"></div>
               <div className="container position-relative">
                   <div className="row">
                       <div className="col-lg-5 col-md-6">
                           <div className="app-subscribe text-center text-md-start">
                               <img src="asset/images/app/bottom.png" className="img-fluid" alt=""/>
                           </div>
                       </div>
   
                       <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                           <div className="section-title text-center text-md-start">
                               <h1 className="title text-white title-dark mb-2">Subscribe to our weekly news</h1>
                               <p className="text-light para-dark">Sign up and receive the latest tips via email.</p>
                           </div>
                           <div className="subcribe-form mt-4 pt-2">
                               <form className="ms-0">
                                   <div className="mb-2">
                                       <input type="email" id="email" name="email" className="rounded-pill border" placeholder="E-mail :"/>
                                       <button type="submit" className="btn btn-pills btn-primary">Subscribe</button>
                                   </div>
                               </form>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
         
           <footer className="footer">    
               <div className="container">
                   <div className="row">
                       <div className="col-12">
                           <div className="footer-py-60">
                               <div className="row">
                                   <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                       <a href="#" className="logo-footer">
                                           <img src="asset/images/heme_logo.png" height="24" alt=""/><img src="asset/images/heme.png" height="24" alt=""/>
                                       </a>
                                       <p className="mt-4">Start using Heme App, it can give your health history,health related document and statistics.</p>
                                       <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                           <li className="list-inline-item"><a href="javascript:void(0)" className="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook fea icon-sm fea-social"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></a></li>
                                           <li className="list-inline-item"><a href="javascript:void(0)" className="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram fea icon-sm fea-social"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></a></li>
                                           <li className="list-inline-item"><a href="javascript:void(0)" className="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter fea icon-sm fea-social"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></a></li>
                                           <li className="list-inline-item"><a href="javascript:void(0)" className="rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin fea icon-sm fea-social"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></a></li>
                                       </ul>
                                   </div>
                                   
                                   <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                       <h5 className="footer-head">Company</h5>
                                       <ul className="list-unstyled footer-list mt-4">
                                           <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Home</a></li>
                                           <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> About Heme</a></li>
                                           <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Features</a></li>
                                           <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Solutions</a></li>
                                           <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Join Our Beta</a></li>
                                           <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Contact Us</a></li>
                                       </ul>
                                   </div>
                                   
                                   <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                       <h5 className="footer-head">Useful Links</h5>
                                       <ul className="list-unstyled footer-list mt-4">
                                           <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Terms of Services</a></li>
                                           <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Privacy Policy</a></li>
                                           <li><a href="javascript:void(0)" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> Documentation</a></li>
                                       </ul>
                                   </div>
               
                                   <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                       <h5 className="footer-head">Join Our Beta</h5>
                                       <p className="mt-4">Get early access to our Beta - we’ll intimate once we’re ready to roll-out to a select privileged few!</p>
                                       <form>
                                           <div className="row">
                                               <div className="col-lg-12">
                                                   <div className="foot-subscribe mb-3">
                                                       <label className="form-label">Write your email <span className="text-danger">*</span></label>
                                                       <div className="form-icon position-relative">
                                                           <i data-feather="mail" className="fea icon-sm icons"></i>
                                                           <input type="email" name="email" id="emailsubscribe" className="form-control ps-5 rounded" placeholder="Your email : " required/>
                                                       </div>
                                                   </div>
                                               </div>
                                               <div className="col-lg-12">
                                                   <div className="d-grid">
                                                       <input type="submit" id="submitsubscribe" name="send" className="btn btn-soft-primary" value="Subscribe"/>
                                                   </div>
                                               </div>
                                           </div>
                                       </form>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
   
               <div className="footer-py-30 footer-bar">
                   <div className="container text-center">
                       <div className="row align-items-center">
                           <div className="col-sm-6">
                               <div className="text-sm-start">
                                   <p className="mb-0">© <script>document.write(new Date().getFullYear())</script> HEME</p>
                               </div>
                           </div>
       
                           
                       </div>
                   </div>
               </div>
           </footer>
           
           <div className="offcanvas offcanvas-end bg-white shadow" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
               <div className="offcanvas-header p-4 border-bottom">
                   <h5 id="offcanvasRightLabel" className="mb-0">
                       <img src="" height="24" className="light-version" alt=""/>
                       <img src="" height="24" className="dark-version" alt=""/>
                   </h5>
                   <button type="button" className="btn-close d-flex align-items-center text-dark" data-bs-dismiss="offcanvas" aria-label="Close"><i className="uil uil-times fs-4"></i></button>
               </div>
               <div className="offcanvas-body p-4">
                   <div className="row">
                       <div className="col-12">
                           <img src="asset/images/contact.svg" className="img-fluid d-block mx-auto" style={{maxWidth: '256px'}} alt=""/>
                           <div className="card border-0 mt-5" style={{zIndex: '1'}}>
                               <div className="card-body p-0">
                                   <form method="post" name="myForm" onsubmit="return validateForm()">
                                       <p id="error-msg" className="mb-0"></p>
                                       <div id="simple-msg"></div>
                                       <div className="row">
                                           <div className="col-md-6">
                                               <div className="mb-3">
                                                   <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                                   <div className="form-icon position-relative">
                                                       <i data-feather="user" className="fea icon-sm icons"></i>
                                                       <input name="name" id="name" type="text" className="form-control ps-5" placeholder="Name :"/>
                                                   </div>
                                               </div>
                                           </div>
       
                                           <div className="col-md-6">
                                               <div className="mb-3">
                                                   <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                   <div className="form-icon position-relative">
                                                       <i data-feather="mail" className="fea icon-sm icons"></i>
                                                       <input name="email" id="email" type="email" className="form-control ps-5" placeholder="Email :"/>
                                                   </div>
                                               </div> 
                                           </div>
       
                                           <div className="col-12">
                                               <div className="mb-3">
                                                   <label className="form-label">Subject</label>
                                                   <div className="form-icon position-relative">
                                                       <i data-feather="book" className="fea icon-sm icons"></i>
                                                       <input name="subject" id="subject" className="form-control ps-5" placeholder="subject :"/>
                                                   </div>
                                               </div>
                                           </div>
       
                                           <div className="col-12">
                                               <div className="mb-3">
                                                   <label className="form-label">Comments <span className="text-danger">*</span></label>
                                                   <div className="form-icon position-relative">
                                                       <i data-feather="message-circle" className="fea icon-sm icons clearfix"></i>
                                                       <textarea name="comments" id="comments" rows="4" className="form-control ps-5" placeholder="Message :"></textarea>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="row">
                                           <div className="col-12">
                                               <div className="d-grid">
                                                   <button type="submit" id="submit" name="send" className="btn btn-primary">Send Message</button>
                                               </div>
                                           </div>
                                       </div>
                                   </form>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
   
               <div className="offcanvas-footer p-4 border-top text-center">
                   <ul className="list-unstyled social-icon social mb-0">
                       <li className="list-inline-item mb-0"><a href="https://dribbble.com/" target="_blank" className="rounded"><i className="uil uil-dribbble align-middle" title="dribbble"></i></a></li>
                       <li className="list-inline-item mb-0"><a href="https://www.facebook.com/" target="_blank" className="rounded"><i className="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
                       <li className="list-inline-item mb-0"><a href="https://www.instagram.com/" target="_blank" className="rounded"><i className="uil uil-instagram align-middle" title="instagram"></i></a></li>
                       <li className="list-inline-item mb-0"><a href="https://twitter.com/" target="_blank" className="rounded"><i className="uil uil-twitter align-middle" title="twitter"></i></a></li>
                       <li className="list-inline-item mb-0"><a href="" className="rounded"><i className="uil uil-envelope align-middle" title="email"></i></a></li>
                       <li className="list-inline-item mb-0"><a href="" target="_blank" className="rounded"><i className="uil uil-globe align-middle" title="website"></i></a></li>
                   </ul>
               </div>
           </div>
           
           {/* <div className="cookie-popup bg-white shadow rounded py-3 px-4">
               <p className="text-muted mb-0">This website uses cookies to provide you with a great user experience. By using it, you accept our <a href="" target="_blank" className="text-success h6">use of cookies</a></p>
               <div className="cookie-popup-actions text-end">
                   <button><i className="uil uil-times text-dark fs-4"></i></button>
               </div>
           </div> */}
           <a href="#" onclick="topFunction()" id="back-to-top" className="back-to-top fs-5"><i data-feather="arrow-up" className="fea icon-sm icons align-middle"></i></a>
           
   
           
   
           
       
           </div>
           </>
        );
    }
}

export default About;