import React from "react";

class Beta extends React.Component{
    render(){
        return(
           <>
           <div>
           <h1>Beta Page</h1>
           </div>
           </>
        );
    }
}

export default Beta;