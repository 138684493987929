// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #arrow {
    border-right:2px solid #707C80;
    border-bottom:2px solid #707C80;
    width:10px;
    height:10px;
    transform: rotate(-45deg);
    margin-top:40px; */
/* } */

ul.arrow-list li {
  position: relative;
	padding-bottom: 10px;
}

ul.arrow-list {
  list-style: none;
}

ul.arrow-list li:before{
   content: '';
   position: absolute;
   border-right:2px solid #707C80;
   border-bottom:2px solid #707C80;
   width:10px;
   height:10px;
   top: calc(13px);
   left: -20px;
   transform: translateY(-50%) rotate(-45deg);
}

ul.arrow-list > li {
    /* font-size: 18px; */
                /* letter-spacing: 0px; */
                color: '#707C80';
      /* padding-bottom: 10px; */
  }

.align-items-center {
    /* display: flex; */
    /* justify-content: center; 
    align-items: center;  */
  }


  
`, "",{"version":3,"sources":["webpack://./src/pages/Contest.css"],"names":[],"mappings":"AAAA;;;;;;sBAMsB;AACtB,MAAM;;AAEN;EACE,kBAAkB;CACnB,oBAAoB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;GACG,WAAW;GACX,kBAAkB;GAClB,8BAA8B;GAC9B,+BAA+B;GAC/B,UAAU;GACV,WAAW;GACX,eAAe;GACf,WAAW;GACX,0CAA0C;AAC7C;;AAEA;IACI,qBAAqB;gBACT,yBAAyB;gBACzB,gBAAgB;MAC1B,0BAA0B;EAC9B;;AAEF;IACI,mBAAmB;IACnB;2BACuB;EACzB","sourcesContent":["/* #arrow {\n    border-right:2px solid #707C80;\n    border-bottom:2px solid #707C80;\n    width:10px;\n    height:10px;\n    transform: rotate(-45deg);\n    margin-top:40px; */\n/* } */\n\nul.arrow-list li {\n  position: relative;\n\tpadding-bottom: 10px;\n}\n\nul.arrow-list {\n  list-style: none;\n}\n\nul.arrow-list li:before{\n   content: '';\n   position: absolute;\n   border-right:2px solid #707C80;\n   border-bottom:2px solid #707C80;\n   width:10px;\n   height:10px;\n   top: calc(13px);\n   left: -20px;\n   transform: translateY(-50%) rotate(-45deg);\n}\n\nul.arrow-list > li {\n    /* font-size: 18px; */\n                /* letter-spacing: 0px; */\n                color: '#707C80';\n      /* padding-bottom: 10px; */\n  }\n\n.align-items-center {\n    /* display: flex; */\n    /* justify-content: center; \n    align-items: center;  */\n  }\n\n\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
