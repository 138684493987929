import React, { useState } from "react";
import Footer from "./Footer";
import Loader from "../components/Loader/Loader";

const toggleMenu = () => {
  document.getElementById("isToggle").classList.toggle("open");
  var isOpen = document.getElementById("navigation");
  if (isOpen.style.display === "block") {
    isOpen.style.display = "none";
  } else {
    isOpen.style.display = "block";
  }
};

const ForLabs = () => {

  const [state, setState] = useState(
    {
      name: '',
      email: '',
      mobile: '',
      designation: "",
      message: '',
      isSending: false
    }
  )

  const [isSubmiting, setIsSubmiting] = useState(false)

  const handleChange = (event) => {
    setState({ value: event.target.value });
  }
  const abc = () => {
    window.location.reload(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsSubmiting(true)

    const form = document.forms['myForm']
    const name = document.querySelector('#name');
    const email = document.querySelector('#email');
    const mobile = document.querySelector('#mobile');
    const designation = document.querySelector('#designation');
    const message = document.querySelector('#message');
    let formData = new FormData();
    formData.append("name", name.value);
    formData.append("email", email.value);
    formData.append("mobile", mobile.value);
    formData.append("designation", designation.value);
    formData.append("message", message.value);
    const url = "https://script.google.com/macros/s/AKfycbz8NLAcNr36bUY0tm078U5B96K4CT7tJv9ocxbac5q36ImuHb9yUQbmKhou_tFLL5ij/exec"

    fetch(url,
      {
        method: 'POST',
        body: formData,

      })
      .then(res => res.json())
      .then((html) => {
        handleAPIModal();
      })
      .catch((error) => {
        console.log("url")
      }).finally(() => {
        setIsSubmiting(false)
      });

  }

  const handleAPIModal = () => {
    // show_modal('myModal1');
  }
  
  // show_modal = (cnt) => {
  //   // $(".modal-backdrop").addClass("show");
  //   // $("#" + cnt).addClass("show");
  //   // $("#" + cnt).show();
  // };

  return (
    <div className="App">
      <head>
        <title>Free Lab Management tool for Labs - HemeHealth</title>
      </head>
      <header id="topnav" className="defaultscroll sticky">
        <div className="container">
          <a className="logo" href="/">
            <img
              src="asset/images/logo.svg"
              className="logo-light-mode"
              id="logo-image"
              alt=""
            />
            <img
              src="asset/images/logo.svg"
              className="logo-dark-mode"
              alt=""
            />
          </a>

          <div className="menu-extras">
            <div className="menu-item">
              <a
                className="navbar-toggle"
                id="isToggle"
                onClick={() => toggleMenu()}
              >
                <img
                  src="asset/images/icon/hemburger-icon.svg"
                  style={{ marginTop: 25 }}
                />
              </a>
            </div>
          </div>

          <div id="navigation">
            <ul className="navigation-menu">
              <li className="parent-parent-menu-item">
                <a className="sub-menu-item" href="/">
                  For Patients
                </a>
                {/* <NavLink to="/" activeClassName="active" exact className="sub-menu-item" onClick={scrollToTop}>For Patients</NavLink> */}
              </li>
              <li className="parent-parent-menu-item" style={{}}>
                {/* <NavLink to="/ForLabs" activeClassName="active" className="sub-menu-item" onClick={scrollToTop}>For Labs</NavLink> */}
                <a
                  className="sub-menu-item active"
                  style={{
                    background: "linear-gradient(to right,#2477B3, #0099CC)",
                    paddingLeft: 30,
                    paddingRight: 30,
                    borderRadius: 45,
                    height: 10,
                    paddingTop: 10,
                    paddingBottom: 33,
                    margin: 15,
                    color: "#fff",
                  }}
                >
                  For Labs
                </a>
              </li>
              {/* <li className="parent-parent-menu-item" style={{}}><a className="sub-menu-item" href="/ReferWin" 
              style={{
                border: '1px solid #E50493',
               paddingLeft: 30,
               paddingRight: 30,
               borderRadius: 45,
               height: 10,
               paddingTop: 10,
               paddingBottom: 33,
               margin: 15,
               color: '#E50493',
             }}
              >Refer & Win</a></li> */}
              {/* <li className="parent-parent-menu-item" style={{}}><a className="sub-menu-item">For Doctors</a></li> */}
            </ul>
          </div>
        </div>
      </header>
      <section
        class="bg-home d-flex align-items-center"
        style={{
          // backgroundImage: `url(${labBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: 788,
          maxWidth: "100%",
        }}
        id="home"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 text-center mt-0 mt-md-5 pt-0 pt-md-5">
              <div className="title-heading margin-top-100">
                <h2 className="heading" style={{}}>
                  Revolutionize your lab management with our{" "}
                  <img src="asset/images/app/free-tag.svg" />
                  tool
                </h2>
                <p
                  className="para-desc mx-auto"
                  style={{ fontSize: 20, color: "#808080" }}
                >
                  Easily manage reports, share results, and earn incentives
                </p>
              </div>
            </div>

            <div className="col-lg-12">
              <img
                src="asset/images/app/Portal-Screenshot@2x.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2" style={{}}>
                <h4
                  className="title mb-4"
                  id="feature-title"
                  style={{ color: "#000" }}
                >
                  Maximize your lab’s efficiency
                  <br /> with HemeHealth
                </h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-12 mt-4 pt-2">
              <div
                className="card features feature-primary explore-feature shadow border-0 rounded text-center"
                style={{ borderColor: "gray" }}
              >
                <div className="card-body py-5">
                  <div
                    className="icons rounded-circle shadow-lg d-inline-block"
                    style={{ backgroundColor: "#05B2DC" }}
                  >
                    <img src="asset/images/icon/feature-icon1.svg" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="" style={{ color: "#05B2DC" }}>
                      Get your lab organized for FREE
                    </h5>
                    <p className="text-muted small">
                      We will setup your account to get you started
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: 10,
                    backgroundColor: "#05B2DC",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                ></div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 pt-2">
              <div
                className="card features feature-primary explore-feature shadow border-0 rounded text-center mt-100"
                id="card-view"
                style={{}}
              >
                <div className="card-body py-5">
                  <div
                    className="icons rounded-circle shadow-lg d-inline-block"
                    style={{ backgroundColor: "#E8871E" }}
                  >
                    <img src="asset/images/icon/feature-icon2.svg" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="" style={{ color: "#E8871E" }}>
                      Streamline report management
                    </h5>
                    <p className="text-muted small">
                      Efficiently manage and store reports
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: 10,
                    backgroundColor: "#E8871E",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                ></div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 pt-2">
              <div className="card features feature-primary explore-feature shadow border-0 rounded text-center">
                <div className="card-body py-5">
                  <div
                    className="icons rounded-circle shadow-lg d-inline-block"
                    style={{ backgroundColor: "#4CAF50" }}
                  >
                    <img src="asset/images/icon/feature-icon3.svg" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="" style={{ color: "#4CAF50" }}>
                      Instant report delivery to patients & doctors
                    </h5>
                    <p className="text-muted small">
                      Easily send reports to patients & doctors
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: 10,
                    backgroundColor: "#4CAF50",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                ></div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 pt-2">
              <div
                className="card features feature-primary explore-feature shadow border-0 rounded text-center mt-100"
                id="card-view"
                style={{}}
              >
                <div className="card-body py-5">
                  <div
                    className="icons rounded-circle shadow-lg d-inline-block"
                    style={{ backgroundColor: "#FF547C" }}
                  >
                    <img src="asset/images/icon/feature-icon4.svg" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="" style={{ color: "#FF547C" }}>
                      Earn incentives every report sent via portal
                    </h5>
                    <p className="text-muted small">
                      Incentives for adding new reports
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: 10,
                    backgroundColor: "#FF547C",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                ></div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 pt-2">
              <div className="card features feature-primary explore-feature shadow border-0 rounded text-center">
                <div className="card-body py-5">
                  <div
                    className="icons rounded-circle shadow-lg d-inline-block"
                    style={{ backgroundColor: "#ED4A4A" }}
                  >
                    <img src="asset/images/icon/feature-icon5.svg" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="" style={{ color: "#ED4A4A" }}>
                      Lab statistics
                    </h5>
                    <p className="text-muted small">
                      Track & analyze reports, on-boarded patients etc.
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: 10,
                    backgroundColor: "#ED4A4A",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                ></div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 pt-2">
              <div
                className="card features feature-primary explore-feature shadow border-0 rounded text-center mt-100"
                id="card-view"
              >
                <div className="card-body py-5">
                  <div
                    className="icons rounded-circle shadow-lg d-inline-block"
                    style={{ backgroundColor: "#A4C242" }}
                  >
                    <img src="asset/images/icon/feature-icon6.svg" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="" style={{ color: "#A4C242" }}>
                      Build a comprehensive test catalogue
                    </h5>
                    <p className="text-muted small">
                      These catalogue will allow app users to book a test
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: 10,
                    backgroundColor: "#A4C242",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                ></div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 pt-2">
              <div className="card features feature-primary explore-feature shadow border-0 rounded text-center">
                <div className="card-body py-5">
                  <div
                    className="icons rounded-circle shadow-lg d-inline-block"
                    style={{ backgroundColor: "#EBC81C" }}
                  >
                    <img src="asset/images/icon/feature-icon7.svg" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="" style={{ color: "#EBC81C" }}>
                      Store & share dicom files
                    </h5>
                    <p className="text-muted small">
                      No need to worry about compatibility, security & file size
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: 10,
                    backgroundColor: "#EBC81C",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                ></div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-12 mt-4 pt-2">
              <div
                className="card features feature-primary explore-feature shadow border-0 rounded text-center mt-100"
                id="card-view"
                style={{}}
              >
                <div className="card-body py-5">
                  <div
                    className="icons rounded-circle shadow-lg d-inline-block"
                    style={{ backgroundColor: "#0066CC" }}
                  >
                    <img src="asset/images/icon/feature-icon8.svg" />
                  </div>
                  <div className="content mt-3">
                    <h5 className="" style={{ color: "#0066CC" }}>
                      Secure & safe
                    </h5>
                    <p className="text-muted small">
                      Only you own your data and is not shared with others
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: 10,
                    backgroundColor: "#0066CC",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div style={{ height: 200 }}></div>
      </section>

      <section
        class="section pt-0"
        style={{
          // backgroundImage: `url(${testimonialBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="video-solution-cta position-relative" style={{}}>
                <div
                  className=""
                  style={{
                    backgroundColor: "#0066CC",
                    borderRadius: 32,
                    border: "10px solid #fff",
                    borderColor: "#fff",
                    width: "100%",
                    marginTop: -80,
                  }}
                >
                  <div className="container" style={{}}>
                    <div className="row" style={{ paddingTop: 20 }}>
                      <div className="col-lg-4 col-md-4 my-4">
                        <div className="d-flex justify-content-center">
                          <img
                            src="asset/images/labsicon.svg"
                            height={60}
                            style={{ marginLeft: 10 }}
                          />
                          <div className="counter-box text-center px-lg-4">
                            <i className="uil uil-users-alt text-white-50 h2"></i>
                            <h1 className="mb-0 text-white">
                              <span className="counter-value" data-target="51">
                                1
                              </span>
                              +
                            </h1>
                            <h6 className="counter-head text-white">
                              Labs onboarded
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-4 my-4">
                        <div className="d-flex justify-content-center">
                          <img
                            src="asset/images/happypatients.svg"
                            height={60}
                            style={{ marginLeft: 10 }}
                          />
                          <div class="counter-box text-center px-lg-4">
                            <i class="uil uil-schedule text-white-50 h2"></i>
                            <h1 class="mb-0 text-white">
                              <span class="counter-value" data-target="1780">
                                1000
                              </span>
                              +
                            </h1>
                            <h6 class="counter-head text-white">
                              Happy patients
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-4 my-4">
                        <div className="d-flex justify-content-center">
                          <img
                            src="asset/images/reportsicon.svg"
                            height={60}
                            style={{ marginLeft: 10 }}
                          />
                          <div class="counter-box text-center px-lg-4">
                            <i class="uil uil-file-check-alt text-white-50 h2"></i>
                            <h1 class="mb-0 text-white">
                              <span class="counter-value" data-target="18761">
                                10000
                              </span>
                              +
                            </h1>
                            <h6 class="counter-head text-white">
                              Reports shared
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="content mt-md-5 mt-4">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="section-title text-center pb-2">
                        <h4 className="title">
                          What our Partners have to say!
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-4">
                      <div className="tiny-single-item">
                        <div className="tiny-slide">
                          <div className="d-flex client-testi m-2">
                            <div className="row flex-column-reverse flex-md-row  justify-content-center">
                              <div className="col-lg-2">
                                <img
                                  src="asset/images/app/testimonial-image-1@2x.png"
                                  id="testi-image"
                                  style={{}}
                                  className="client-image rounded"
                                  alt=""
                                />
                              </div>
                              <div className="col-lg-6">
                                <p
                                  className=""
                                  style={{ color: "#808080" }}
                                  id="testi-text"
                                >
                                  <i>
                                    "Heme Health's portal is a game-changer for
                                    our lab. It's user-friendly and allows us to
                                    store and access reports efficiently,
                                    streamlining our operations. The search and
                                    filtering options have reduced the risk of
                                    errors and saved us valuable time."
                                  </i>
                                </p>
                                <h6
                                  className="text-primary"
                                  id="testi-text"
                                  style={{ color: "#0099CC" }}
                                >
                                  Global pathological lab
                                </h6>
                                <h6 className="text-primary" id="testi-text">
                                  <small className="text-muted">
                                    Sagar Talaviya - CEO
                                  </small>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tiny-slide">
                          <div className="d-flex client-testi m-2">
                            <div className="row flex-column-reverse flex-md-row  justify-content-center">
                              <div className="col-lg-2">
                                <img
                                  src="asset/images/app/testimonial-image-2@2x.png"
                                  id="testi-image"
                                  style={{}}
                                  className="client-image rounded"
                                  alt=""
                                />
                              </div>
                              <div className="col-lg-6">
                                <p
                                  className=""
                                  style={{ color: "#808080" }}
                                  id="testi-text"
                                >
                                  <i>
                                    "Heme Health's portal is a fantastic tool
                                    for managing lab reports. It's
                                    user-friendly, efficient, and has helped us
                                    streamline our operations. We also
                                    appreciate the incentive program that
                                    rewards labs for using the portal. Highly
                                    recommended!"
                                  </i>
                                </p>
                                <h6 className="text-primary" id="testi-text">
                                  Body scan imaging
                                </h6>
                                <h6 className="text-primary" id="testi-text">
                                  <small className="text-muted">
                                    Dr. Bhavesh Ramani – Owner
                                  </small>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className=""
        id="contact"
        style={{
          // backgroundImage: `url(${contactForm})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center pb-2">
                <h4 className="title text-white" style={{ marginTop: 100 }}>
                  Get in touch with us
                </h4>
                <p className="para-desc text-white mx-auto mb-0">
                  We’re here to help. Fill out the form and we’ll respond as
                  soon as we can.
                </p>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            {/* <p style={{ color: "red", textAlign: "center" }}>
              {errorMessage ? errorMessage : null}
            </p> */}

            <div
              className="col-lg-12 col-md-12 col-12 mt-4 pt-2"
              style={{ padding: 100 }}
            >
              <div className="">
                <div className="">
                  <form
                    method="POST"
                    id="myForm"
                    //action="https://script.google.com/macros/s/AKfycbz8NLAcNr36bUY0tm078U5B96K4CT7tJv9ocxbac5q36ImuHb9yUQbmKhou_tFLL5ij/exec"
                    onSubmit={submitHandler}

                  >
                    <p className="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div className="row" id="row-input">
                      <div className="col-md-6">
                        <div
                          class="form-icon position-relative"
                          style={{ marginBottom: 10 }}
                        >
                          <img
                            id="input-icon"
                            src="asset/images/icon/nameicon.svg"
                          />
                          <input
                            style={{ paddingLeft: 40 }}
                            name="name"
                            id="name"
                            type="text"
                            class="form-control ps-5"
                            placeholder="Your Name :"
                            value={state.value}
                            onChange={handleChange}

                          />
                        </div>
                      </div>

                      <div className="col-md-6" id="col-input-box">
                        <div class="form-icon position-relative">
                          <img
                            id="input-icon"
                            src="asset/images/icon/emailicon.svg"
                          />
                          <input
                            style={{ paddingLeft: 40 }}
                            name="email"
                            id="email"
                            type="text"
                            class="form-control ps-5"
                            placeholder="Email :"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          class="form-icon position-relative"
                          style={{ marginBottom: 10 }}
                        >
                          <img
                            id="input-icon"
                            src="asset/images/icon/mobicon.svg"
                          />
                          <input
                            style={{ paddingLeft: 40 }}
                            name="mobile"
                            id="mobile"
                            type="number"
                            class="form-control ps-5"
                            placeholder="Mobile :"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6" id="col-input-box">
                        <div class="form-icon position-relative">
                          <img
                            id="input-icon"
                            src="asset/images/icon/desigicon.svg"
                          />
                          <input
                            style={{ paddingLeft: 40 }}
                            name="designation"
                            id="designation"
                            type="text"
                            class="form-control ps-5"
                            placeholder="Role/Designation :"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div class="form-icon position-relative">
                          <img
                            id="input-messicon"
                            src="asset/images/icon/messicon.svg"
                          />
                          <textarea
                            style={{ paddingLeft: 40 }}
                            name="message"
                            id="message"
                            rows="4"
                            className="form-control"
                            placeholder="Message :"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row py-5 justify-content-center">
                      <div className="col-lg-2">
                        <div className="d-grid">
                          <button
                            disabled={isSubmiting}
                            type="submit"
                            class="btn"
                            style={{
                              borderWidth: 3,
                              borderRadius: 24,
                              backgroundColor: "#FF547C",
                              borderColor: "#fff",
                              color: "#fff",
                            }}
                          >
                            {isSubmiting ? <Loader /> : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <div
        id="myModal1"
        class="modal fade"
        role="dialog"
        style={{ backgroundColor: "#093A78", opacity: 1 }}
      >
        <div class="modal-dialog">
          <div class="modal-content" style={{ backgroundColor: "#3EC1E5" }}>
            <div class="modal-header"></div>
            <div class="modal-body">
              <img
                src="asset/images/icon/thanks.svg"
                alt=""
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <p
                className="text-light"
                style={{ textAlign: "center", fontSize: 40 }}
              >
                Thank you!
              </p>
              <p
                className="text-light"
                style={{ textAlign: "center", fontSize: 24 }}
              >
                We’ll get in touch as soon as we can.
              </p>
            </div>
            <div class="modal-footer" style={{ borderTop: 0 }}>
              <button
                onClick={
                  abc
                }
                type="button"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: 24,
                  width: "35%",
                  borderWidth: 3,
                }}
                className="btn btn-primary1"
                data-dismiss="modal"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


}

export default ForLabs;