import React from "react";
import { Link } from 'react-router-dom';
import bannerImage from "../images/Header-background@2x.png";
import bannerImage1 from "../images/abdm-background@2x.png";
import forLabs from "../images/for-labs-background-image@2x.png"
import forDoctors from "../images/for-doctors-background-image@2x.png"
import Header from "./Header";
import Footer from "./Footer";

export default function HomeNew() {
  const toggleMenu =()=> {
  
    document.getElementById('isToggle').classList.toggle('open');
    var isOpen = document.getElementById('navigation')
    if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
    } else {
        isOpen.style.display = "block";
    }
};
  
  return (
    <div className="App">
       <header id="topnav" className="defaultscroll sticky"
      
      >
            <div className="container" id="menudraw">
               
                <a className="logo" href="/">
                   
                    <img src="asset/images/logo.svg"  className="logo-light-mode" id="logo-image"  alt=""/>
                    <img src="asset/images/logo.svg" className="logo-dark-mode"  alt=""/>
                </a>

                <div className="menu-extras">
                  <div className="menu-item">
                    <a className="navbar-toggle" id="isToggle" onClick={()=>toggleMenu()}>
                      <img className="mt-4" src="asset/images/icon/hemburger-icon.svg" style={{}}  />
                    </a>
                  </div>
                </div>

                <div id="navigation" >
                       <ul className="navigation-menu">
                           <li className="parent-parent-menu-item"><a className="sub-menu-item active" style= {{background: "linear-gradient(to right,#2477B3, #0099CC)",paddingLeft:30,paddingRight:30,borderRadius:45,height:10,paddingTop:10,paddingBottom:33,margin:15,color:"#fff",
    }}>For Patients</a></li>
                           <li className="parent-parent-menu-item" style={{}}><a className="sub-menu-item" href="/ForLabs" >For Labs</a></li>
                           <li className="parent-parent-menu-item" style={{}}><a className="sub-menu-item">For Doctors</a></li>
                       </ul>

                        
                </div>
               
              
            </div>
            </header>
   

  
  <div id="carouselExampleDark" className="carousel carousel-dark slide slide vertical d-md-flex flex-column-reverse flex-md-row-reverse" data-bs-ride="carousel" style={{
                backgroundImage: `url(${bannerImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                paddingTop:150,
                paddingBottom:100,
                maxWidth:"100%"
              }}>
  <div className="carousel-indicators d-flex flex-wrap justify-content-center flex-md-row align-items-md-center">
    <button id="btn-indicator" type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" ></button>
    <button id="btn-indicator" type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button id="btn-indicator" type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="3000">
      
      <div className="carousel-caption">
      <div class="container">
                <div class="row flex-column-reverse flex-md-row align-items-center mt-5 mt-sm-0">
                    <div class="col-md-7">
                        <div class="title-heading text-center text-md-start">

                            <h4 class="heading mb-3 mt-2">India’s first digital locker to support DICOM files</h4>
                            <p class="text-muted mb-0 para-dark para-desc mx-auto ms-md-auto" id="bannerP">Manage all medical reports, labs, DICOM files (MRI & CT Scans) Safe & Secure.</p>
                            
                            <div className="mt-4 pt-2">
                        <a href="https://apple.co/3CLxrQu">
                          <img
                            src="asset/images/app/appstore.svg"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>

                        <a href="https://bit.ly/3xAMh9r">
                          <img
                            src="asset/images/app/playstore.svg"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>
                      </div>
                        </div>
                    </div>

                    <div class="col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="freelance-hero position-relative">
                            <div class="position-relative">
                                <img src="asset/images/app/Conceptual-illustration-1.svg" class="mx-auto d-block img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
    <div className="carousel-item" data-bs-interval="3000">
   
      <div className="carousel-caption">
       
      <div class="container">
                <div class="row flex-column-reverse flex-md-row align-items-center mt-5 mt-sm-0">
                    <div class="col-md-7">
                        <div class="title-heading text-center text-md-start">

                            <h4 class="heading mb-3 mt-2">Consent-Based Sharing of Healthcare data</h4>
                            <p id="bannerP" class="text-muted mb-0 para-dark para-desc mx-auto ms-md-auto">Protect privacy, maintain control, & secure your healthcare data through consent-based sharing with Heme Health.</p>
                            
                            <div className="mt-4 pt-2">
                        <a href="https://apple.co/3CLxrQu">
                          <img
                            src="asset/images/app/appstore.svg"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>

                        <a href="https://bit.ly/3xAMh9r">
                          <img
                            src="asset/images/app/playstore.svg"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>
                      </div>
                        </div>
                    </div>

                    <div class="col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="freelance-hero position-relative">
                            <div class="position-relative">
                                <img src="asset/images/app/Conceptual-illustration-2.svg" class="mx-auto d-block img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                  
      </div>
      
    </div>
    <div className="carousel-item" data-bs-interval="3000">
    
      <div className="carousel-caption">
      <div class="container">
                <div class="row flex-column-reverse flex-md-row align-items-center mt-5 mt-sm-0">
                    <div class="col-md-7">
                        <div class="title-heading text-center text-md-start">

                            <h4 class="heading mb-3 mt-2">Track vitals, set reminders & improve health</h4>
                            <p id="bannerP" class="text-muted mb-0 para-dark para-desc mx-auto ms-md-auto">Build your health profile and manage conditions like Blood Pressure & Sugar</p>
                            
                            <div className="mt-4 pt-2">
                        <a href="https://apple.co/3CLxrQu">
                          <img
                            src="asset/images/app/appstore.svg"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>

                        <a href="https://bit.ly/3xAMh9r">
                          <img
                            src="asset/images/app/playstore.svg"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>
                      </div>
                        </div>
                    </div>

                    <div class="col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="freelance-hero position-relative">
                            <div class="position-relative">
                                <img src="asset/images/app/Conceptual-illustration-3.svg" class="mx-auto d-block img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  </div>
  
</div>




        
        <section className="section bg-light">
          <div className="container">
          <div className="row justify-content-center"> 
        <img src="asset/images/app/graph-spectrum-desktop-view.svg" id="graph-desktop-only" className="img-fluid" alt=""/>
        <img src="asset/images/app/graph-spectrum-mobile-view.svg" id="graph-mobile-only" className="img-fluid" alt=""/>
        </div>
        </div>
        </section>

        <section className="section"
              style={{
                backgroundImage: `url(${bannerImage1})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                maxHeight:"100%",
                maxWidth:"100%"
              }}
              >
                <div className="container">
            <div className="row align-items-center">
              <div className="col-12 text-center">
              <div className="title-heading mb-4">
              <h1 className="text-light title" style={{fontSize:40}}><b>Hemehealth is a partner of ABDM</b></h1>
              <p className="text-light para-desc mb-0 mx-auto" id="gov" style={{fontSize:28}}>a Government of India Initiative</p>
              </div>
              </div>
            
            <div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                     <img src="asset/images/app/abdm-illustration.svg" className="img-fluid" alt=""/>

                    </div>
                    <div className="col-lg-7 col-md-7" id="abdm">
                      
                        
                        <p className="text-light para-desc" style={{fontSize:14}}>One of the main goals of the <b>Ayushman Bharat Digital Mission</b> is to create a comprehensive, interoperable, and secure health information system that can be used to improve the delivery of healthcare service.</p>
                        <p className="text-light para-desc" style={{fontSize:14}}>This includes digitizing health records, creating digital health (ABHA) ID’s. This will help to improve the coordination and continuity of care for patients, as well as provide real-time data and analytics to health care providers and policymakers.</p>
                        <p className="text-light para-desc" style={{fontSize:14}}><b>Create your health account now. Download the App.</b></p>
                        <div className="mt-4 pt-2">
                        <a href="https://apple.co/3CLxrQu">
                          <img
                            src="asset/images/app/appstore.svg"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>

                        <a href="https://bit.ly/3xAMh9r">
                          <img
                            src="asset/images/app/playstore.svg"
                            className="m-1"
                            height="50"
                            alt=""
                          />
                        </a>
                        </div>
                    </div>
                    
                </div>
           </div>

              </section>

 <section>
          <div className="container-fluid">
            <div className="row align-items-center d-flex flex-column-reverse flex-md-row " style={{}}>
              <div className="col-lg-6 order-1 order-md-2" style={{
                backgroundImage: `url(${forDoctors})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height:578,
                maxWidth:"100%",
                //bottom:17
              }}>
                <div className="d-flex flex-column align-items-start" style={{marginTop:200,marginLeft:80}}>
                <h3 className="heading mb3 " style={{}}>For Doctors</h3>
                <p className="para-desc" style={{color:"#808080"}}><b>Free portal</b> wherein you get<br /> patient’s historic data easily<br /> and quickly.</p>
                <a className="btn btn-pills btn-outline-primary" style={{}}>Know more</a>
                </div>
                
              </div>
              <div className="col-lg-6 order-2 order-md-1" style={{
                backgroundImage: `url(${forLabs})`,
                backgroundSize: "cover",
               backgroundPosition: "center center",
                height:578,
                maxWidth:"100%",
                //bottom:17,
                
              }}>
                <div className="d-flex flex-column" style={{marginTop:200,float:"right",marginRight:50}}>
              <h3 className="heading mb3" style={{}}>For Labs</h3>
                <p className="para-desc" style={{color:"#808080",}}><b>Free Portal</b>   to share reports<br /> with patients via WhatsApp,<br /> manage reports, track metrics.</p>
              <a className="btn btn-pills btn-outline-primary" href="/ForLabs" style={{width:"60%"}}>Know more</a>
              </div>
              </div>
            </div>
          </div>
          </section>
  
<Footer />
       
    </div>
  );
}
