import React, { useEffect } from "react";
import Footer from "./Footer";
import "./ReferWin.css";
import { useLocation } from "react-router-dom";

const ReferWin = () => {
  const location = useLocation();

  useEffect(() => {
    const metaDescription =
      "Participate in our Refer & Win contest and stand a chance to win official merchandise of the Rajasthan Royals! Simply share your referral link with your contacts and get at least one successful referral to be eligible for the prize. Don't miss out on this exciting opportunity to win big – start referring today!";
    const descriptionTag = document.querySelector('meta[name="description"]');
    descriptionTag.setAttribute("content", metaDescription);
  }, [location.pathname]);

  const reloadPage = () => {
    window.location.reload(false);
  };

  const toggleMenu = () => {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  };

  return (
    <div className="App">
      <head>
        <title>Refer & Win Official Merchandise of the Rajasthan Royals!</title>
      </head>

      <header id="topnav" className="defaultscroll sticky">
        <div className="container">
          <a className="logo" href="/">
            <img
              src="asset/images/logo.svg"
              className="logo-light-mode"
              id="logo-image"
              alt=""
            />
            <img
              src="asset/images/logo.svg"
              className="logo-dark-mode"
              alt=""
            />
          </a>

          <div className="menu-extras">
            <div className="menu-item">
              <a
                className="navbar-toggle"
                id="isToggle"
                onClick={() => toggleMenu()}
              >
                <img
                  src="asset/images/icon/hemburger-icon.svg"
                  style={{ marginTop: 25 }}
                />
              </a>
            </div>
          </div>

          <div id="navigation">
            <ul className="navigation-menu">
              <li className="parent-parent-menu-item">
                <a className="sub-menu-item" href="/">
                  For Patients
                </a>
              </li>
              <li className="parent-parent-menu-item" style={{}}>
                <a className="sub-menu-item" href="/ForLabs">
                  For Labs
                </a>
              </li>
              <li className="parent-parent-menu-item" style={{}}>
                <a
                  className="sub-menu-item active"
                  style={{
                    background: "linear-gradient(to right,#E50493, #C7007E)",
                    paddingLeft: 30,
                    paddingRight: 30,
                    borderRadius: 45,
                    height: 10,
                    paddingTop: 10,
                    paddingBottom: 33,
                    margin: 15,
                    color: "#fff",
                  }}
                >
                  Refer & Win
                </a>
              </li>
              {/* <li className="parent-parent-menu-item" style={{}}><a className="sub-menu-item">For Doctors</a></li> */}
            </ul>
          </div>
        </div>
      </header>

      <section>
        <div style={{ height: 75 }}></div>
      </section>

      {/* for desktop view */}
      <section
        class="bg-home d-flex align-items-center d-md-block d-none"
        style={{
          backgroundImage: `url("asset/images/refer-win/banner-image.png")`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: 500,
          maxWidth: "100%",
        }}
        id="home"
      >
        <div className="container refer-win-left">
          <div className="row flex-column-reverse flex-md-row align-items-center mt-5 mt-sm-0 refer-win-left-inner">
            <div className="col-md-7">
              <div className="title-heading text-center text-md-start align-items-center">
                <h4
                  className="heading mb-3 mt-2"
                  style={{
                    color: "#fff",
                  }}
                >
                  Refer & Win Contest has Concluded
                </h4>
                <p
                  id="bannerP"
                  style={{
                    color: "#fff",
                  }}
                  className="mb-0 para-desc mx-auto ms-md-auto"
                >
                  Winners Announcement Below!
                </p>

                <div className="mt-4 pt-2">
                  <a href="https://bit.ly/3xAMh9r">
                    <img
                      src="asset/images/app/playstore.svg"
                      className="m-1"
                      height="50"
                      alt=""
                    />
                  </a>
                  <a href="https://apple.co/3CLxrQu">
                    <img
                      src="asset/images/app/appstore.svg"
                      className="m-1"
                      height="50"
                      alt=""
                    />
                  </a>
                  <div class="col-xs-12" style={{ height: "50px" }}></div>
                  <a
                    style={{
                      textAlign: "left",
                      textDecoration: "underline",
                      letterSpacing: 0,
                      color: "#FFFFFF",
                      opacity: 1,
                      fontSize: 16,
                    }}
                    href="/ContestTerms"
                  >
                    <p
                      style={{
                        textDecorationLine: "underline",
                      }}
                    >
                      Contest Terms & Conditions
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* for mobile view */}
      <div className="d-block d-md-none m-10">
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            backgroundColor: "#f88379",
            color: "#FFF",
          }}
        >
          Refer & Win Contest has Concluded
        </div>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            backgroundColor: "#f88379",
            color: "#FFF",
          }}
        >
          Winners Announcement Below!
        </div>
        <img
          src="asset/images/refer-win/mobile-contest-banner.png"
          alt=""
          className="img-fluid"
        />
      </div>
      <div className="d-block d-sm-none">
        <div
          className="d-flex justify-content-center pb-4 pt-4"
          style={{ backgroundColor: "#2c408e", overflow: "hidden" }}
        >
          <a href="https://bit.ly/3xAMh9r">
            <img
              src="asset/images/app/playstore.svg"
              className="m-1"
              height="50"
              alt=""
            />
          </a>
          <a href="https://apple.co/3CLxrQu">
            <img
              src="asset/images/app/appstore.svg"
              className="m-1"
              height="50"
              alt=""
            />
          </a>
        </div>
      </div>

      {/* for desktop view */}
      <section
        class="bg-home d-flex align-items-center d-md-block d-none"
        style={{
          // backgroundImage: `url(${labBanner})`,
          // backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "auto",
          maxWidth: "100%",
          marginBottom: 50,
          paddingTop: 50,
        }}
        id="home"
      >
        <div className="container">
          <div className="justify-content-center">
            {/* <img
              src="asset/images/refer-win/participation-guidelines.png"
              alt=""
              className="img-fluid"
            /> */}
            <img
              style={{ width: "100%", borderRadius: 20 }}
              src="asset/images/refer-win/winner-announcements-mobile2.jpg"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      {/* for mobile view */}
      <div
        className="d-block d-md-none"
        style={{
          padding: 20,
        }}
      >
        <div className="m-10">
          <img
            style={{ width: "100%", borderRadius: 20 }}
            src="asset/images/refer-win/winner-announcements-mobile2.jpg"
            alt=""
            className="img-fluid"
          />
        </div>
      </div>

      {/* <section>
        <div style={{ height: 50 }}></div>
      </section> */}

      {/* for desktop view */}
      {/* <section
        class="bg-home d-flex align-items-center d-md-block d-none"
        style={{
          backgroundPosition: "center center",
          height: "auto",
          maxWidth: "100%",
          marginBottom: 45,
        }}
        id="home"
      >
        <div className="container">
          <div className="justify-content-center">
            <img
              src="asset/images/refer-win/prizes.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section> */}

      {/* for mobile view */}
      {/* <div
        className="d-block d-md-none"
        style={{
          padding: 20,
        }}
      >
        <img
          src="asset/images/refer-win/mobile-prizes.png"
          alt=""
          className="img-fluid"
        />
      </div> */}

      <div className="container">
        <a
          style={{
            textAlign: "left",
            textDecoration: "underline",
            letterSpacing: 0,
            color: "#E50493",
            opacity: 1,
            fontSize: 16,
          }}
          className="mb-0 para-desc mx-auto ms-md-auto"
          href="/ContestTerms"
        >
          <u>*Contest Terms & Conditions</u>
        </a>
      </div>

      <section>
        <div style={{ height: 50 }}></div>
      </section>

      <Footer />

      <div
        id="myModal1"
        class="modal fade"
        role="dialog"
        style={{ backgroundColor: "#093A78", opacity: 1 }}
      >
        <div class="modal-dialog">
          <div class="modal-content" style={{ backgroundColor: "#3EC1E5" }}>
            <div class="modal-header"></div>
            <div class="modal-body">
              <img
                src="asset/images/icon/thanks.svg"
                alt=""
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <p
                className="text-light"
                style={{ textAlign: "center", fontSize: 40 }}
              >
                Thank you!
              </p>
              <p
                className="text-light"
                style={{ textAlign: "center", fontSize: 24 }}
              >
                We’ll get in touch as soon as we can.
              </p>
            </div>
            <div class="modal-footer" style={{ borderTop: 0 }}>
              <button
                onClick={reloadPage}
                type="button"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: 24,
                  width: "35%",
                  borderWidth: 3,
                }}
                className="btn btn-primary1"
                data-dismiss="modal"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferWin;
