import React,{useState} from 'react';


const toggleMenu =()=> {
  
    document.getElementById('isToggle').classList.toggle('open');
    var isOpen = document.getElementById('navigation')
    if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
    } else {
        isOpen.style.display = "block";
    }
};

export default function Header() {
    const [active,setActive] = useState('')
  return (
    <div>
       <header id="topnav" className="defaultscroll sticky"
      
      >
            <div className="container">
               
                <a className="logo" href="/">
                   
                    <img src="asset/images/logo.svg"  className="logo-light-mode" alt=""/>
                    <img src="asset/images/logo.svg" className="logo-dark-mode" alt=""/>
                </a>

                <div className="menu-extras">
                  <div className="menu-item">
                    <a className="navbar-toggle" id="isToggle" onClick={()=>toggleMenu()}>
                      <img src="asset/images/icon/hemburger-icon.svg" style={{marginTop:25}} />
                    </a>
                  </div>
                </div>

                <div id="navigation" >
                       <ul className="navigation-menu">
                           <li className="parent-parent-menu-item"><a className="sub-menu-item active" style= {{background: "linear-gradient(to right,#2477B3, #0099CC)",paddingLeft:30,paddingRight:30,borderRadius:45,height:10,paddingTop:10,paddingBottom:33,margin:15,color:"#fff",
    }}>For Patients</a></li>
                           <li className="parent-parent-menu-item" style={{}}><a className="sub-menu-item" href="/ForLabs" >For Labs</a></li>
                           <li className="parent-parent-menu-item" style={{}}><a className="sub-menu-item">For Doctors</a></li>
                       </ul>

                        
                </div>
               
              
            </div>
            </header>
    </div>
  );
}
